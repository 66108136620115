.serviceTextContainer{
  h2, p, li{
    color: #f2f0f8;
  }
  h2{
    text-align: center;
  }
  p{
    a{
    color: #599fd2;
      text-decoration: none;
      transition: 0.3s;
      &:hover{
        color: #ffffff;
        transition: 0.3s;
      }
    }

  }
  #addressP{
    margin-bottom: 0;
  }
  span{
    color: #f2f0f8;
    margin-top: 0;
  }
  ul{
    padding-left: 20px;
    li{
      margin-top: 5px;
    }
  }
   @media(min-width: 10px){
    width: 320px;
    h2{
      font-size: 20px;
    }
    p, li{
      font-size: 14px;
    }
    span{
      font-size: 8px;
    }
  }
  @media (min-width: 770px) {
    width: 770px;
    h2{
      font-size: 24px;
    }
    p, li{
      font-size: 16px;
    }
    span{
      font-size: 9px;
    }
  }
  @media (min-width: 1000px) {
    width: 1000px;
    h2{
      font-size: 30px;
    }
    p, li{
      font-size: 18px;
    }
    span{
      font-size: 10px;
    }
  }
  @media (min-width: 1280px) {
    width: 1280px;
    h2{
      font-size: 36px;
    }
    p, li{
      font-size: 20px;
    }
  }
}