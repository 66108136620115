@media (min-width: 10px) {
  .cartContainer {
    width: 320px;
    margin: 0 auto;
    padding: 10px;
    background: none;
    h2{
      font-size: 22px;
      font-weight: 600;
      margin: 5px 0;
    }


  }
  .problemContainer {
    width: 320px;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    color: #495990;

    h2{
      text-align: center;
    }
    h4 {
      color: black;
      text-align: center;
    }

  }
}
@media (min-width: 770px) {
  .cartContainer {
    width: 770px;
    padding: 0 16px;
    background: none;
    h2{
      font-size: 24px;
    }

  }
  .problemContainer {
    width: 770px;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    color: #495990;

    h4 {
      color: black;
    }

  }
}
@media (min-width: 1000px) {
  .cartContainer {
    width: 1000px;
    padding: 0 18px;
    background: none;
    h2{
      font-size: 28px;
    }

  }
  .problemContainer {
    width: 1000px;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: #495990;

    h4 {
      color: black;
    }

  }
}
@media (min-width: 1280px) {
  .cartContainer {
    width: 1280px;
    padding: 0 20px;
    background: none;
    h2{
      font-size: 32px;
    }

  }
  .problemContainer {
    width: 1280px;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    color: #495990;

    h4 {
      color: black;
    }

  }
}