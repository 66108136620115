 @media(min-width: 10px){
  .socialLinksContainer {
    width: 147px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    //align-items: center;
    img {
      transition: 0.3s;
      max-width: 34px;
      max-height: 34px;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
@media (min-width: 720px) {

  .socialLinksContainer {
    width: 147px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      transition: 0.3s;
      max-width: 34px;
      max-height: 34px;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
@media (min-width: 1000px) {

  .socialLinksContainer {
    width: 186px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      transition: 0.3s;
      max-width: 42px;
      max-height: 42px;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
@media (min-width: 1280px) {

  .socialLinksContainer {
    width: 200px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      transition: 0.3s;
      max-width: 50px;
      max-height: 50px;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
