.importCsvFormDiv {
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #f2f0f8; /* Білий фон */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 800px;

  form {
    width: 100%; /* Ширина форми */
    display: flex;
    flex-direction: column;
    input {
      font-size: 15px;
      width: 100%; /* Повна ширина */
      padding: 10px; /* Відступ всередині полів вводу */
      margin-bottom: 20px; /* Відступ між полями вводу */
      border: none;
      box-sizing: border-box; /* Коробкова модель для правильного обчислення ширини */
    }
    button {
      width: 100%; /* Повна ширина */
      padding: 10px; /* Відступ всередині кнопки */
      background-color: #b04941;
      color: #f2f0f8; /* Білий текст кнопки */
      border: none;/* Закруглення кутів кнопки */
      cursor: pointer;
      margin-bottom: 20px; /* Відступ знизу кнопки */
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      transition: 0.3s;
    }
    button:hover {
      background-color: #d57570;
      transition: 0.3s;
    }
    span {
      color: #de3333; /* Червоний колір для повідомлень про помилки */
      margin-bottom: 10px; /* Відступ знизу повідомлення */
      display: block; /* Повідомлення про помилки на новому рядку */
    }
  }
}