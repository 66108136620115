html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  //overflow-y: hidden;
}
.mainLayout {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  height: auto;
  background-color: #F2F0F8;

  width: 100vw;

  .contentContainer {
     @media(min-width: 10px){
      padding-top: 60px;
      padding-bottom: 980px;
    }

    @media (min-width: 770px) {
      padding-top: 88px;
      padding-bottom: 460px;
    }
    @media (min-width: 1000px) {
      padding-top: 115px;
      padding-bottom: 580px;

    }
    @media (min-width: 1280px) {
      padding-top: 140px;
      padding-bottom: 650px;
    }
  }
}

