 @media(min-width: 10px){
  .me {
    position: relative;
    width: 40px;
    height: 40px;

    .authMenuButton {
      background: none;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;

      .avatar, img {
        border-radius: 50%;
        object-fit: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #F2F0F8;
        transition: 0.3s;
        min-height: 100%;
        min-width: 100%;
      }

      .avatar {
        font-size: 16px;
      }

      .avatar:hover, img:hover {
        transition: 0.3s;
        transform: scale(1.1);
      }
    }
    .meMenu {
      background-color: #f2f0f8;
      position: absolute;
      bottom: 50px;
      left: -80px;
      right: auto;
      height: auto;
      transform: translateX(0);
      width: 200px;
      box-shadow: 0 2px 10px rgba(0,0,0,0.2);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s, visibility 0.3s;

      .menuItem {
        padding: 10px;
        transition: 0.3s;
        font-weight: 600;
        font-size: 14px;
        width: 90%;
        text-align: right;
        cursor: pointer;
        &:hover {
          transform: scale(1.1);
          transition: 0.3s;
          color: #F2F0F8;
        }
      }
    }

    &:hover .meMenu {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.3s, visibility 0.3s;
    }
  }
}
@media (min-width: 770px) {
  .me {
    position: relative;
    margin-left: 15px;
    width: 34px;
    height: 34px;
    .authMenuButton {
      background: none;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      .avatar, img {
        border-radius: 50%;
        object-fit: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #F2F0F8;
        transition: 0.3s;
        min-height: 100%;
        min-width: 100%;
      }

      .avatar {
        font-size: 16px;
      }

      .avatar:hover, img:hover {
        transition: 0.3s;
        transform: scale(1.1);
      }
    }

    .meMenu {
      position: absolute;
      background-color: rgba(242, 240, 248, 0.89);
      top: 54px;
      left: -150px;
      right: 0;
      height: 240px;
      z-index: 100;
      padding: 10px;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s, visibility 0.3s;

      .menuItem {
        padding: 10px;
        transition: 0.3s;
        font-weight: 600;
        font-size: 14px;
        width: 90%;
        text-align: right;

        &:hover {
          transition: 0.3s;
          color: #F2F0F8;
        }
      }
    }

    &:hover .meMenu {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.3s, visibility 0.3s;
    }
  }
}
@media (min-width: 1000px) {
  .me {
    position: relative;
    margin-left: 20px;
    width: 42px;
    height: 42px;
    .authMenuButton {
      background: none;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      .avatar, img {
        border-radius: 50%;
        object-fit: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #F2F0F8;
        transition: 0.3s;
        min-height: 100%;
        min-width: 100%;
      }

      .avatar {
        font-size: 20px;
      }

      .avatar:hover, img:hover {
        transition: 0.3s;
        transform: scale(1.1);
      }
    }

    .meMenu {
      position: absolute;
      background-color: rgba(242, 240, 248, 0.89);
      top: 54px;
      left: -150px;
      right: 0;
      z-index: 100;
      padding: 10px;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s, visibility 0.3s;

      .menuItem {
        padding: 10px;
        transition: 0.3s;
        font-weight: 600;
        font-size: 15px;
        width: 90%;
        text-align: right;

        &:hover {
          transition: 0.3s;
          color: #F2F0F8;
        }
      }
    }

    &:hover .meMenu {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.3s, visibility 0.3s;
    }
  }
}

@media (min-width: 1280px) {
  .me {
    position: relative;
    margin-left: 25px;
    width: 50px;
    height: 50px;
    .authMenuButton {
      background: none;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      .avatar, img {
        border-radius: 50%;
        object-fit: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #F2F0F8;
        transition: 0.3s;
        min-height: 100%;
        min-width: 100%;
      }

      .avatar {
        font-size: 24px;
      }

      .avatar:hover, img:hover {
        transition: 0.3s;
        transform: scale(1.1);
      }
    }

    .meMenu {
      position: absolute;
      background-color: rgba(242, 240, 248, 0.89);
      top: 54px;
      left: -150px;
      right: 0;
      z-index: 100;
      padding: 10px;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s, visibility 0.3s;

      .menuItem {
        padding: 10px;
        transition: 0.3s;
        font-weight: 600;
        font-size: 16px;
        width: 90%;
        text-align: right;

        &:hover {
          transition: 0.3s;
          color: #F2F0F8;
        }
      }
    }

    &:hover .meMenu {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.3s, visibility 0.3s;
    }
  }
}
