  .orderInputsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .orderInputDiv {
      background-color: #edebf1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
      transition: 0.3s;
      color: #4A4949;

      input {
        width: 70%;
        height: 100%;
        border: none;
        background-color: #edebf1;
        transition: 0.3s;
        font-family: "Montserrat Alternates", sans-serif;
        font-weight: 500;

        &:focus {
          outline: none;
        }
      }



    }
    .inputButton {
      background: none;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s;

      &:hover{
        background-color: #b04941;
      }
      img {
        transition: 0.3s;

        &:hover {
          transition: 0.3s;
          transform: scale(1.05);
        }
      }
    }
    @media (min-width: 10px) {
      width: 320px;
      .orderInputDiv {
        height: 20px;
        width: 50px;
        input {
          padding: 0 5px;
          font-size: 8px;
        }
      }
      .inputButton {
        width: 20px;
        height: 20px;
        img {
          height: 20px;
        }
      }
    }
    @media (min-width: 770px) {
      width: 770px;
      .orderInputDiv {
        height: 40px;
        width: 100px;
        input {
          padding: 0 5px;
          font-size: 10px;
        }
      }
      .inputButton {
        width: 60px;
        height: 30px;
        img {
          height: 30px;
        }
      }
    }
    @media (min-width: 1000px) {
      width: 1000px;
      .orderInputDiv {
        height: 40px;
        width: 140px;
        input {
          padding: 0 10px;
          font-size: 11px;
        }
      }
      .inputButton {
        width: 80px;
        height: 40px;
        img {
          height: 35px;
        }
      }
    }
    @media (min-width: 1280px) {
      width: 1280px;
      .orderInputDiv {
        height: 50px;
        width: 180px;
        input {
          padding: 0 15px;
          font-size: 13px;
        }
      }
      .inputButton {
        width: 100px;
        height: 60px;
        img {
          height: 40px;
        }
      }
    }
  }

