 @media(min-width: 10px){
  .HeaderLeftSide {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logoLink {
      display: flex;
      align-items: center;
      transition: 0.3s;
      width: 40px;
      height: 40px;
      &:hover {
        transform: scale(1.03);
      }

      .logo {
        height: 100%;
      }
    }
  }
}
@media (min-width: 770px) {
  .HeaderLeftSide {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .menuButton {
      border: none;
      background: none;
      height: 60%;
      cursor: pointer;
      margin-right: 10px;
      transition: 0.4s;

      img {
        height: 100%;
      }
    }

    .logoLink {
      display: flex;
      align-items: center;
      transition: 0.3s;
      width: 60px;
      height: 60px;
      &:hover {
        transform: scale(1.03);
      }

      .logo {
        height: 100%;
      }
    }
  }
}
@media (min-width: 1000px) {
  .HeaderLeftSide {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .menuButton {
      border: none;
      background: none;
      height: 60%;
      cursor: pointer;
      margin-right: 10px;
      transition: 0.4s;

      img {
        height: 100%;
      }
    }

    .logoLink {
      display: flex;
      align-items: center;
      transition: 0.3s;
      width: 75px;
      height: 75px;
      &:hover {
        transform: scale(1.03);
      }

      .logo {
        height: 100%;
      }
    }

    //@media (min-width: 770px) {
    //  .logoLink {
    //    width: 63px;
    //    height: 63px;
  }
}
@media (min-width: 1280px) {
  .HeaderLeftSide {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .menuButton {
      border: none;
      background: none;
      height: 60%;
      cursor: pointer;
      margin-right: 10px;
      transition: 0.4s;

      img {
        height: 100%;
      }
    }

    .logoLink {
      display: flex;
      align-items: center;
      transition: 0.3s;
      width: 90px;
      height: 90px;
      &:hover {
        transform: scale(1.03);
      }

      .logo {
        height: 100%;
      }
    }

    //@media (min-width: 770px) {
    //  .logoLink {
    //    width: 63px;
    //    height: 63px;
    //  }
    //}
    //
    //@media (min-width: 1000px) {
    //  .logoLink {
    //    width: 80px;
    //    height: 80px;
    //  }
    //}
  }
}
