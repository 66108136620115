.usaBottomImageContainer{
    transition: 0.3s;
    position: relative;
    background-color: #599080;
    img{
      width: 100%;
      box-sizing: border-box;
      object-fit: cover;

      transition: 0.3s;
    }
    p{
      color: #f2f0f8;
      position: absolute;
      margin: 0;
    }
    @media(min-width: 200px) {
      width: 770px;
      display: flex;
      justify-content: center;
      img{
        height: 330px;
        object-position: center calc(100% + 60px);
      }
      p{
        font-size: 20px;
        font-weight: 700;
        top: 8px;
        right: auto;
        width: 300px;
        text-align: center;
      }

    }
    @media(min-width: 770px) {
      width: 1000px;

      img{
        height: 392px;
        object-position: center calc(100% + 150px);
      }
      p{
        font-size: 23px;
        font-weight: 700;
        top: 40px;
        right: 130px;
        text-align: right;
        width: 380px;
      }

    }
    @media(min-width: 1000px) {
      width: 1280px;
      img{
        height: 500px;
        object-position: center calc(100% + 200px);
      }
      p{
        width: 400px;
        font-size: 26px;
        font-weight: 700;
        top: 40px;
        right: 150px;
      }

    }
    @media(min-width: 1280px) {
      width: 1480px;
      img{
        height: 596px;
      }
      p{
        width: 400px;
        font-size: 26px;
        font-weight: 600;
        top: 50px;
        right: 110px;
      }

    }


}
