.evacFeatures {
  width: 100vw;
  display: flex;
  justify-content: center;
  transition: 0.3s;
  .evacFeaturesContainer {
    display: flex;
    align-items: center;
    transition: 0.3s;
    .evacFeature {
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: 0.3s;
      .evacFeatureImageDiv {
        img {
          box-sizing: border-box;
          transition: 0.3s;
        }
      }

      .evacFeatureTextDiv {

        font-weight: 100;
        color: #f2f0f8;
        transition: 0.3s;
      }
    }
     @media(min-width: 10px){
      width: 300px;
      height: 220px;
      flex-direction: column;
      justify-content: space-around;
      .evacFeature {
        width: 280px;

        .evacFeatureImageDiv {
          img {
            width: 40px;
            height: 40px;
          }
        }

        .evacFeatureTextDiv {
          font-size: 12px;
          font-weight: 500;
          text-align: right;
        }
      }
    }
    @media (min-width: 770px) {
      width: 770px;
      height: 96px;
      flex-direction: row;
      justify-content: space-between;
      .evacFeature {
        width: 200px;

        .evacFeatureImageDiv {
          img {
            width: 44px;
            height: 44px;
          }
        }

        .evacFeatureTextDiv {
          font-size: 12px;
          text-align: center;
        }
      }
    }
    @media (min-width: 1000px) {
      width: 1000px;
      height: 120px;
      .evacFeature {
        width: 250px;

        .evacFeatureImageDiv {
          img {
            width: 54px;
            height: 54px;
          }
        }

        .evacFeatureTextDiv {
          font-size: 14px;
        }
      }
    }
    @media (min-width: 1280px) {
      width: 1280px;
      height: 144px;
      .evacFeature {
        width: 300px;

        .evacFeatureImageDiv {
          img {
            width: 64px;
            height: 64px;
          }
        }

        .evacFeatureTextDiv {
          font-size: 16px;
        }
      }
    }
  }
}