 @media(min-width: 10px){
  .taglineText {
    text-align: justify-all;
    transition: 0.3s;
    width: 320px;
    h1 {
      font-size: 24px;
      text-align: center;

    }
  }
  .taglineImage {
    transition: 0.3s;
    width: 270px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
    img {
      width: 100%;
      height: auto;
    }
  }
}
@media (min-width: 770px) {
  .tagLine {
    display: flex;
    justify-content: center;
    width: 100vw;
    background: none;

    .tagLineContainer {
      width: 770px;
      justify-content: space-between;
      align-items: center;
      display: flex;
      transition: 0.3s;
      .taglineText {
        text-align: justify-all;
        transition: 0.3s;
        width: 400px;
        h1 {
          font-size: 24px;
        }
      }
      .taglineImage {
        transition: 0.3s;
        width: 280px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
@media (min-width: 1000px) {
  .tagLine {
    display: flex;
    justify-content: center;
    width: 100vw;

    .tagLineContainer {
      width: 1000px;
      justify-content: space-between;
      align-items: center;
      display: flex;
      transition: 0.3s;
      .taglineText {
        text-align: justify-all;
        transition: 0.3s;
        width: 500px;
        h1 {
          font-size: 30px;
        }
      }
      .taglineImage {
        transition: 0.3s;
        width: 350px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

@media (min-width: 1280px) {
  .tagLine {
    display: flex;
    justify-content: center;
    width: 100vw;

    .tagLineContainer {
      width: 1280px;
      justify-content: space-between;
      align-items: center;
      display: flex;
      transition: 0.3s;
      .taglineText {
        text-align: justify-all;
        transition: 0.3s;
        width: 600px;
        h1 {
          font-size: 36px;
        }
      }
      .taglineImage {
        transition: 0.3s;
        width: 420px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}