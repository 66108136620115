.serviceFormDiv{
  transition: 0.3s;
  width: 100wv;
  display: flex;
  align-items: center;
  justify-content: center;
  form{
    background-color: #AAA9A9;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    p{
      transition: 0.3s;
      color: #3E3E40;
      font-weight: 600;
      margin: 0;
    }
    input{
      transition: 0.3s;
      background-color: #f2f0f8;
      border: none;
      border-radius: 0;
      font-family: "Montserrat Alternates", sans-serif;
      color: #3E3E40;
    }
    button{
      background-color: #599fd2;
      border: none;
      font-family: "Montserrat Alternates", sans-serif;
      font-weight: 600;
      transition: 0.3s;
      color: #f2f0f8;
      &:hover{
        background-color: #4395d0;
        transition: 0.3s;
      }
    }

  }
   @media(min-width: 10px){
    height: 550px;
    form{
      width: 320px;
      height: 480px;
      p{
        font-size: 20px;
      }
      input{
        width: 260px;
        height: 34px;
        padding-left: 14px;
        font-size: 16px;
      }
      button{
        width: 252px;
        height: 31px;
        font-size: 16px;
      }
    }
  }
  @media (min-width: 770px) {
    height: 534px;
    form{
      width: 472px;
      height: 466px;
      p{
        font-size: 20px;
      }
      input{
        width: 408px;
        height: 34px;
        padding-left: 14px;
        font-size: 16px;
      }
      button{
        width: 252px;
        height: 31px;
        font-size: 16px;
      }
    }
  }
  @media (min-width: 1000px) {
    height: 666px;
    form{
      width: 591px;
      height: 584px;
      p{
        font-size: 26px;
      }
      input{
        width: 510px;
        height: 42px;
        padding-left: 17px;
        font-size: 19px;
      }
      button{
        width: 315px;
        height: 38px;
        font-size: 19px;
      }
    }
  }
  @media (min-width: 1280px) {
    height: 800px;
    form{
      width: 710px;
      height: 700px;
      p{
        font-size: 32px;
      }
      input{
        width: 612px;
        height: 50px;
        padding-left: 20px;
        font-size: 22px;
      }
      button{
        width: 378px;
        height: 45px;
        font-size: 22px;
      }
    }
  }
}