.products-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  margin-top: 10px;
  span{
    margin-top: 20px;
  }
  .paginationDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}