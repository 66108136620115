  .mail {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;

    background-image: linear-gradient(to top, rgba(73, 89, 144, 0.68) 0%, #F2F0F8 100%);

    .mailContainer {
      align-items: center;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      transition: 0.3s;
      color: black;
      .mailInnerFormContainer {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {

          font-weight: 700;
        }


      }
    }
     @media(min-width: 10px){
      .mailContainer {
        width: 320px;
        padding-bottom: 10px;
        height: 70px;
        img {
          width: 40px;
        }
        .mailInnerFormContainer {
          p {
            margin-bottom: 6px;
            font-size: 13px;
          }
        }
      }
    }
    @media (min-width: 770px) {
      .mailContainer {
        width: 770px;
        padding-bottom: 10px;
        height: 90px;
        img {
          width: 40px;
        }
        .mailInnerFormContainer {
          p {
            margin-bottom: 6px;
            font-size: 20px;
          }
        }
      }
    }
    @media (min-width: 1000px) {
      .mailContainer {
        width: 1000px;
        padding-bottom: 10px;
        height: 110px;
        img {
          width: 50px;
        }
        .mailInnerFormContainer {
          p {
            margin-bottom: 6px;
            font-size: 22px;
          }
        }
      }
    }
    @media (min-width: 1280px) {
      .mailContainer {
        width: 1280px;
        padding-bottom: 10px;
        height: 110px;
        img {
          width: 60px;
        }
        .mailInnerFormContainer {
          p {
            margin-bottom: 6px;
            font-size: 24px;
          }
        }
      }
    }
  }






