@media (min-width: 770px) {
  .headerRightContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s;


  }
}
@media (min-width: 1000px) {
  .headerRightContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s;


  }
}
@media (min-width: 1280px) {
  .headerRightContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s;


  }
}
