@media(min-width: 200px){
  .product-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;

    .productDetailsContainer {
      height: auto;
      width: 320px;
      background:none; /* Світлий фон */
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      h2{
        color: #495990;
        font-size: 16px;
        font-weight: 700;
        text-align: start;
        width: 100%;
      }
      .productInfoContainer {
        width: 320px;
        box-sizing: border-box;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .productInfo {
          width: 320px;
          height: 150px;
          box-sizing: border-box;
          h3{
            font-size: 12px;
          }
          .priceContainer{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
              font-size:14px ;
              height: 18px;
              margin: 0;
            }
            h3{
              font-size: 14px;
              margin: 0;
            }
          }
          .innerInfoContainer{

            height: 20px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            p{
              font-size:14px ;
              font-weight: 300;
              margin: 0;
            }
            h4{
              margin: 0;
              color: #495990;
              font-size:14px ;
              font-weight: 600;
            }
          }
        }
      }
      .buyButton {
        margin-top: 30px;
        margin-left: 80px;
        background-color: #495990; /* Червоний фон кнопки */
        color: white;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
        width: 160px;
        height: 35px;
        font-size: 16px;
        box-sizing: border-box;
        font-family: "Montserrat Alternates", sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: #c20808; /* Темніший червоний колір при наведенні */
        }
      }

      //.sliderItem {
      //  display: flex;
      //  justify-content: center;
      //  align-items: center;
      //}
      .featuresContainer{
        height:47px ;
        width: 320px;
        display: grid;
        grid-template-columns: repeat(2, 0fr);
        justify-content: space-between;
        margin-top: 60px;
        .feature{
          width: 148px;
          height: 80px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          .textDiv{
            transition: 0.3s;
            font-size: 10px;
            width: 92px;
            p{
              text-align: center;
              transition: 0.3s;
              margin: 0;
            }
          }
          .imageDiv {
            width: 42px;
            height: 42px;
            img {
              height: 100%;
            }
          }
        }
      }
    }
  }

}
@media(min-width: 770px){
  .product-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;

    .productDetailsContainer {
      height: auto;
      width: 770px;
      background:none; /* Світлий фон */
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: normal;
      box-sizing: border-box;
      h2{
        color: #495990;
        font-size: 22px;
        font-weight: 700;
      }
      .productInfoContainer {
        width: 770px;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .productInfo {
          width: 356px;
          height: 230px;
          box-sizing: border-box;
          margin-right: 50px;
          h3{
            font-size: 12px;
          }
          .priceContainer{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h3{
              font-size: 18px;
              margin: 0;
            }
            p{
              font-size:18px ;
              height: 18px;
              margin: 0;
            }

          }
          .innerInfoContainer{
            height: 20px;
            width: 100%;
            display: flex;
            justify-content: space-between;

            p{
              font-size:16px ;
              font-weight: 300;
            }
            h4{
              color: #495990;
              font-size:16px ;
              font-weight: 600;
            }
          }
        }
      }
      .buyButton {
        margin-top: 80px;
        background-color: #495990; /* Червоний фон кнопки */
        color: white;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
        width: 132px;
        height: 30px;
        font-size: 16px;
        box-sizing: border-box;
        font-family: "Montserrat Alternates", sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: #c20808; /* Темніший червоний колір при наведенні */
        }
      }

      //.sliderItem {
      //  display: flex;
      //  justify-content: center;
      //  align-items: center;
      //}
      .featuresContainer{
        height:47px ;
        width: 700px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 40px;
        .feature{
          width: 148px;
          height: 47px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          .textDiv{
            transition: 0.3s;
            font-size: 10px;
            width: 92px;
            p{
              text-align: center;
              transition: 0.3s;
              margin: 0;
            }
          }
          .imageDiv {
            width: 42px;
            height: 42px;
            img {
              height: 100%;
            }
          }
        }
      }
    }
  }

}
@media(min-width: 1000px){
  .product-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;

    .productDetailsContainer {
      height: auto;
      width: 1000px;
      background:none; /* Світлий фон */
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      box-sizing: border-box;
      h2{
        color: #495990;
        font-size: 25px;
        font-weight: 700;
      }
      .productInfoContainer {
        width: 1000px;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .productInfo {
          width: 445px;
          height: 288px;
          box-sizing: border-box;
          margin-right: 100px;
          h3{
            font-size: 14px;
          }
          .priceContainer{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
              font-size:20px ;
              height: 20px;
              margin: 0;
            }
            h3{
              font-size: 25px;
              margin: 0;
            }
          }
          .innerInfoContainer{
            height: 25px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            p{
              font-size:20px ;
              font-weight: 300;
            }
            h4{
              color: #495990;
              font-size:20px ;
              font-weight: 600;
            }
          }
        }
      }
      .buyButton {
        margin-top: 104px;
        background-color: #495990; /* Червоний фон кнопки */
        color: white;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
        width: 166px;
        height: 38px;
        font-size: 20px;
        box-sizing: border-box;
        font-family: "Montserrat Alternates", sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: #c20808; /* Темніший червоний колір при наведенні */
        }
      }

      //.sliderItem {
      //  display: flex;
      //  justify-content: center;
      //  align-items: center;
      //}
      .featuresContainer{
        height:58px ;
        width: 875px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 50px;
        .feature{
          width: 186px;
          height: 58px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          .textDiv{
            transition: 0.3s;
            font-size: 12px;
            width: 115px;
            p{
              text-align: center;
              transition: 0.3s;
              margin: 0;
            }
          }
          .imageDiv {
            width: 54px;
            height: 54px;
            img {
              height: 100%;
            }
          }
        }
      }
    }
  }

}


@media(min-width: 1280px){
  .product-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;


    .productDetailsContainer {
      height: auto;
      width: 1280px;
      background:none; /* Світлий фон */
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      box-sizing: border-box;
      h2{
        color: #495990;
        font-size: 28px;
        font-weight: 700;
      }
      .productInfoContainer {
        width: 1280px;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .productInfo {
          width: 534px;
          height: 345px;
          box-sizing: border-box;
          margin-right: 190px;
          h3{
            font-size: 16px;
          }
          .priceContainer{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
              font-size:22px ;
              height: 22px;
              margin: 0;
            }
            h3{
              font-size: 28px;
              margin: 0;
            }
          }
          .innerInfoContainer{
            height: 30px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            p{
              font-size:22px ;
              font-weight: 300;
            }
            h4{
              color: #495990;
              font-size:22px ;
              font-weight: 600;
            }
          }
        }
      }
      .buyButton {
        margin-top: 124px;
        background-color: #495990; /* Червоний фон кнопки */
        color: white;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
        width: 200px;
        height: 45px;
        font-size: 24px;
        box-sizing: border-box;
        font-family: "Montserrat Alternates", sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: #c20808; /* Темніший червоний колір при наведенні */
        }
      }

      .sliderItem {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .featuresContainer{
        height:68px ;
        width: 1050px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 60px;
        .feature{
          width: 224px;
          height: 68px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          .textDiv{
            transition: 0.3s;
            font-size: 15px;
            width: 138px;
            p{
              text-align: center;
              transition: 0.3s;
              margin: 0;
            }
          }
          .imageDiv {
            width: 64px;
            height: 64px;
            img {
              height: 100%;
            }
          }
        }
      }
    }
  }

}

