html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  //overflow-y: hidden;
}
.usaLayout {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  height: auto;
  background-color: #3E3E40;

  width: 100vw;

  .usaContentContainer {
    @media(min-width: 10px){
      padding-top: 72px;
      padding-bottom: 850px;
    }

    @media (min-width: 770px) {
      padding-top: 85px;
      padding-bottom: 460px;
    }
    @media (min-width: 1000px) {
      padding-top: 110px;
      padding-bottom: 580px;

    }
    @media (min-width: 1280px) {
      padding-top: 135px;
      padding-bottom: 700px;
    }
  }
}