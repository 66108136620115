.MuiTabs-flexContainer{
  display: flex;
  justify-content: center;
}
.LoginRegisterFormDiv {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: none;
  border: 0.5px solid #495990;
  background-color: rgb(255, 255, 255);
  box-shadow:2px 2px 2px 2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  form {

    width: 100%; /* Ширина форми */
    display: flex;
    flex-direction: column;
  }
  input {
    font-family: "Montserrat Alternates", sans-serif;
    font-size: 15px;
    background-color: #f2f0f8;
    width: 100%; /* Повна ширина */
    padding: 10px; /* Відступ всередині полів вводу */
    margin-bottom: 20px; /* Відступ між полями вводу */
    border: 1px solid #dcdcdc; /* Кордон поля вводу */
    box-sizing: border-box;
    border-radius: 0;
  }
  input[type='email'] {
    font-size: 15px;
    width: 100%; /* Повна ширина */
    padding: 10px; /* Відступ всередині полів вводу */
    margin-bottom: 20px; /* Відступ між полями вводу */
    border: 1px solid #dcdcdc; /* Кордон поля вводу */
    box-sizing: border-box; /* Коробкова модель для правильного обчислення ширини */
  }
  button {
    width: 100%; /* Повна ширина */
    padding: 10px; /* Відступ всередині кнопки */
    background-color: #495990; /* Червоний фон кнопки */
    color: #fff; /* Білий текст кнопки */
    border: none;
    cursor: pointer;
    margin-bottom: 20px; /* Відступ знизу кнопки */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Тінь кнопки */
    font-family: "Montserrat Alternates", sans-serif;
    font-size: 18px;
    transition: 0.3s;

  }
  button:hover {
    background-color: #5a73c4;
    transition: 0.3s;
    transform: scale(1.01);
  }
  span {
    color: #495990; /* Червоний колір для повідомлень про помилки */
    margin-bottom: 10px; /* Відступ знизу повідомлення */
    display: block; /* Повідомлення про помилки на новому рядку */
  }
  @media (min-width: 770px) {
    min-width: 500px;
  }
   @media(min-width: 10px){
    width: 320px;

  }
}