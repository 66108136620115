
.evacPrices{
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  .evacPricesContainer{

    display: flex;
    flex-direction: column;
    align-items: center;
    p{
      color: #f2f0f8;
      font-weight: 600;
    }
    .evacPrice{
      display: flex;
      justify-content:space-between ;
      width: 100%;
    }
     @media(min-width: 10px){
      width: 320px;
      p{
        font-size: 16px;
        margin: 6px 0;
      }
    }
    @media (min-width: 770px) {
      width: 600px;
      p{
        font-size: 22px;
        margin: 6px 0;
      }
    }
    @media (min-width: 1000px) {
      width: 750px;
      p{
        font-size: 26px;
        margin: 7px 0;
      }
    }
    @media (min-width: 1280px) {
      width: 900px;
      p{
        font-size: 32px;
        margin: 8px 0;
      }
    }
  }

}