.admin-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: none;
  .admin-loader {
    border: 15px solid #f2f0f8;
    border-top: 15px solid #b04941;
    border-radius: 50%;
    width: 30vw;
    height: 30vw;
    animation: spin 1s linear infinite;
  }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}