.mePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 600px;

  .registeredContainer{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .changeBtn{
      border: none;
      background-color: #495990;
      color: #f2f0f8;
      font-family: "Montserrat Alternates", sans-serif;

      position: absolute;
      top: 0;
      right: 0;
      transition: 0.3s;
      cursor: pointer;

      &:hover{
        background-color: #284198;
        transition: 0.3s;
      }
      @media (min-width: 10px) {
        font-size: 12px;
        padding: 5px 10px;
      }
      @media (min-width: 770px) {
        font-size: 16px;
        padding: 5px 15px;
      }
    }
  }
}