.errorContainer{
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  p{
    color: #3E3E40;
    font-weight: 500;
    margin: 0;
    text-align: center;
  }
  #errorLink{
    text-decoration: none;
    color: #ffffff;
    background-color: #495990;
    font-weight: 500;
    transition: 0.3s;
    &:hover{
      background-color: #646c8d;
      transition: 0.3s;
    }
  }
  img{
    object-fit: cover;
    object-position: center bottom;

  }
  @media(min-width: 10px){
    width: 320px;
    padding-bottom: 30px;
    margin-top: 80px;
    p{
      font-size: 16px;
    }
    a{
      padding: 10px 25px;
      font-size: 16px;

    }
    img{
      width: 320px;
      height: 250px;
    }
    span{
      margin-top: 18px;
      font-size: 12px;

    }
  }
  @media (min-width: 770px) {
    width: 770px;
    padding-bottom: 30px;
    margin-top: 30px;
    p{
      font-size: 24px;
    }
    a{
      padding: 10px 35px;
      font-size: 24px;

    }
    img{
      width: 450px;
      height: 380px;
    }
    span{
      margin-top: 18px;

    }
  }
  @media (min-width: 1000px) {
    width: 1000px;
    padding-bottom: 30px;
    margin-top: 40px;
    p{
      font-size: 28px;
    }
    a{
      padding: 15px 50px;
      font-size: 28px;

    }
    img{
      width: 500px;
      height: 400px;
    }
    span{
      margin-top: 18px;

    }
  }
  @media (min-width: 1280px) {
    width: 1280px;
    padding-bottom: 50px;
    margin-top: 50px;
    p{
      font-size: 32px;
    }
    a{
      padding: 15px 50px;
      font-size: 32px;

    }
    img{
      width: 555px;
      height: 450px;
    }
    span{
      margin-top: 20px;

    }
  }
}