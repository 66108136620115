.cart {
  display: flex;
  align-items: center;
  transition: 0.3s;
  position: relative;
  &:hover {
    transform: scale(1.1);
  }
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    transition: 0.3s;

  }
  .cartCounter{
    position: absolute;
    top: -15%;
    right:-20%;
    background-color: #b04941;
    color: #f2f0f8;
    border-radius: 50%;
    width: 40%;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 90%;
    font-weight: 500;
  }
  @media (min-width: 10px) {
    width: 40px;
    height: 40px;
  }
  @media (min-width: 770px) {
    margin-left: 15px;
    width: 34px;
    height: 34px;
  }
  @media (min-width: 1000px) {
    margin-left: 20px;
    width: 42px;
    height: 42px;
  }
  @media (min-width: 1280px) {
    margin-left: 25px;
    width: 50px;
    height: 50px;
  }
}
