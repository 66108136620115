.meInfo{
  border: 2px solid #495990;

  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);


  form{
    height: 80%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    .meInfoInnerContainer{
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p, h5{
        margin: 0;
      }
      input{
        width: 45%;
        font-family: "Montserrat Alternates", sans-serif;
      }
      h5{
        width: 45%;
        text-align: left;
        color: #495990;
      }
      p{
        color: #3E3E40;
        font-weight: 500;
      }
    }


    }
  span{
    color: green;
    text-align: center;
    width: 30%;
    margin-left: 16%;
  }
  .saveBtn{
    border: none;
    background-color: #495990;
    width: 50%;
    margin-left: 10%;
    font-family: "Montserrat Alternates", sans-serif;
    color: #f2f0f8;
    cursor: pointer;
    transition: 0.3s;
    &:hover{
      background-color: #284198;
      transition: 0.3s;
    }
  }
  @media (min-width: 10px) {
    padding: 16px;
    width: 280px;
    height: 400px;
    form{
      .meInfoInnerContainer{
        p, h5{
          font-size: 16px;
        }
        input{
          height: 18px;
          font-size: 16px;
          padding-left: 4px;
        }
      }
    }
    span{
      font-size: 14px;
    }
    .saveBtn{
      height: 35px;
      margin-top: 30px;
    }
  }
  @media (min-width: 770px) {
    padding: 20px;
    width: 710px;
    height: 400px;
    form{
      .meInfoInnerContainer{
        p, h5{
          font-size: 22px;
        }
        input{
          height: 26px;
          font-size: 22px;
          padding-left: 10px;
        }
      }
    }
    span{
      font-size: 18px;
    }
    .saveBtn{
      height: 45px;
      margin-top: 30px;
    }
  }

}