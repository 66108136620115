$ig-small-screen: 500px !default;
$ig-xsmall-screen: 480px !default;
$ig-white: #fff !default;
$ig-black: #000 !default;
$ig-blue: #495990 !default;
$ig-background-black: rgba(0, 0, 0, .4) !default;
$ig-transparent: rgba(0, 0, 0, 0) !default;
$ig-shadow: 0 2px 2px lighten($ig-black, 10%);

@mixin vendor-prefix($name, $value) {
  @each $vendor in ('-webkit-', '-moz-', '-ms-', '-o-', '') {
    #{$vendor}#{$name}: #{$value};
  }
}
.imageGalleryContainer{
   @media(min-width: 10px){
    margin-bottom: 20px;
  }
  @media (min-width: 770px) {
    margin-bottom: 0;
  }

}
// SVG ICON STYLES
.image-gallery-icon {
  color: $ig-white;
  transition: all .3s ease-out;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  filter: drop-shadow($ig-shadow);


  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: $ig-blue;
      .image-gallery-svg {
        transform: scale(1.1);
      }
    }
  }
}

.image-gallery-using-mouse {
  .image-gallery-icon {
    &:focus {
      outline: none;
    }
  }
}

.image-gallery-fullscreen-button{
  bottom: 0;
  right: 0;
  padding: 20px;
  color: #f2f0f8;
  transition: 0.3s;
  &:hover{
    color: $ig-blue;
    transition: 0.3s;
  }
   @media(min-width: 10px){
    padding: 15px;

    .image-gallery-svg {
      height: 30px;
      width: 30px;
    }
  }

  @media (min-width: 770px) {
    padding: 10px;

    .image-gallery-svg {
      height: 40px;
      width: 40px;
    }
  }
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 10px 10px;
  top: 50%;
  transform: translateY(-50%);

  border-radius: 50%;
  &:hover{
    background-color: rgba(73, 89, 144, 0.16);
    color: #f2f0f8;
  }
   @media(min-width: 10px){
    .image-gallery-svg {
      height: 30px;
      width: 30px;
    }
  }
  @media (min-width: 770px) {
    .image-gallery-svg {
      height: 30px;
      width: 30px;
    }
  }

  @media (min-width: 1000px) {
    .image-gallery-svg {
      height: 40px;
      width: 40px;
    }
  }
  @media (min-width: 1280px) {
    .image-gallery-svg {
      height: 50px;
      width: 50px;
    }
  }
}

.image-gallery-left-nav {
  left: 0;
}

.image-gallery-right-nav {
  right: 0;
}

.image-gallery {
  @include vendor-prefix('user-select', none);
  -webkit-tap-highlight-color: $ig-transparent;
  position: relative;

  &.fullscreen-modal {
    background: rgba(62, 62, 64, 0.96);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;

    .image-gallery-content {

      transform: translateY(-50%);
      .image-gallery-slide .image-gallery-image {
        height: 80vh;
      }
       @media(min-width: 10px){
        top: 35%;
        .image-gallery-slide .image-gallery-image {
          max-width: 320px;
        }
      }
      @media (min-width: 770px) {
        top: 45%;
        .image-gallery-slide .image-gallery-image {
          max-width: 770px;
        }

      }
      @media (min-width: 1000px) {
        top: 46%;
        .image-gallery-slide .image-gallery-image {
          max-width: 1000px;
        }

      }
      @media (min-width: 1280px) {
        top: 47%;
        .image-gallery-slide .image-gallery-image {
          max-width: 1280px;
        }

      }
    }

    .image-gallery-slide .image-gallery-image {
      width: auto; // Автоматичне масштабування ширини
      object-fit: contain; // Зберігає пропорції, не обрізаючи зображення
    }
  }
}

.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0;

  .image-gallery-slide .image-gallery-image {

    object-fit: cover;
     @media(min-width: 10px){
      width: 320px;
      height: 210px;
    }
    @media (min-width: 770px) {
      width: 332px;
      height: 231px;
    }
    @media (min-width: 1000px) {
      width: 416px;
      height: 288px;
    }
    @media (min-width: 1280px) {
      width: 500px;
      height: 345px;
    }
  }
}

.image-gallery-slide-wrapper {
  position: relative;
  display: inline-block;
  width: 100%; // Забезпечує повну ширину контейнера для слайдів
  height: auto; // Дозволяє висоті автоматично адаптуватися
}

.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-align: center;
}

.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &.image-gallery-center {
    position: relative;
  }

  .image-gallery-image {
    width: 100%;
    object-fit: contain;
  }

}
