 @media(min-width: 10px){
  .makeOrderForm {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 320px;
    margin-top: 20px;
    padding: 18px 0;
    background-color: #f2f0f8; /* Білий фон */
    transition: 0.3s;
    .orderFormHeader{
      display: flex;
      justify-content: center;
      font-size: 20px;
      font-weight: 700;
      color: #495990;
      width: 320px;
      height: 40px;
      border-bottom: 1px solid grey;
      transition: 0.3s;
    }
    form {
      display: flex;
      align-items: start;
      justify-content: space-between;
      width: 100%;
      flex-direction: column;
      transition: 0.3s;
      .makeOrderInnerFormContainer {
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        border: 1px solid black;
        margin: 16px 0;
        transition: 0.3s;
        width: 320px;
        .orderFormRegister{
          width: 100%;
          height: 40px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          transition: 0.3s;
          a{
            text-decoration:none ;
            font-size: 14px;
            font-weight: 700;
            color: #495990;
            transition: 0.5s;
          }
          a:hover{
            transition: 0.5s;
            transform: scale(1.01);
            color: #798dcc;
          }
          #regLink{
            font-weight: 600;
          }
        }
        input[type="text"], input[type="email"], select {
          width: 300px;
          box-sizing: border-box;
          height: 34px;
          padding-left: 10px;
          margin: 8px 0;
          border: 1px solid #7c7c7c;
          border-radius: 0;
          font-size: 14px;
          font-family: "Montserrat Alternates", sans-serif;
          background-color: #f2f0f8;
          transition: 0.3s;
        }

        h3 {
          font-size: 18px;
          color: #495990; /* Тонка лінія під заголовком */
          margin-bottom: 10px;
          transition: 0.3s;
        }

        .radioGroupContainer {
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-direction: column;
          width: 300px;
          box-sizing: border-box;
          margin-bottom: 20px;
          transition: 0.3s;

        }

        .deliverySelect {
          width: 300px;
          height: 34px;
          box-sizing: border-box;
          margin: 6px 0;
          padding: 0;
          background-color: #f2f0f8;
          font-size: 14px;
          font-weight: 200;
          text-align: center;
          border: none;
          transition: 0.3s;
        }
        .noPriceP{
          width: 300px;
          box-sizing: border-box;
          font-size: 12px;
          text-align: center;
        }
      }


    }

    h3 {
      color: #de3333; /* Червоний колір тексту */
      margin-top: 16px;
      margin-bottom: 8px;
    }

    button:disabled {
      background-color: #e6e6e6; /* Світло-сірий колір для відключеної кнопки */
      cursor: not-allowed;
    }

    h2 {
      text-align: center;
      margin-top: 16px;
    }
  }
}

@media (min-width: 770px) {
  .makeOrderForm {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 770px; /* Зменшуємо ширину порівняно з корзиною для кращої адаптації під форму */
    margin: 40px auto; /* Центрування форми */
    padding: 18px 0;
    background-color: #f2f0f8; /* Білий фон */
    transition: 0.3s;
    .orderFormHeader{
      display: flex;
      justify-content: center;
      font-size: 20px;
      font-weight: 500;
      width: 100%;
      height: 40px;
      border-bottom: 1px solid grey;
      transition: 0.3s;
    }
    form {
      display: grid;
      grid-template-columns: repeat(2, 0fr);
      align-items: start;
      justify-content: space-between;
      width: 100%;
      flex-direction: column;
      transition: 0.3s;
      .makeOrderInnerFormContainer {
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        border: 1px solid black;
        margin: 16px 0;
        transition: 0.3s;

        .orderFormRegister{
          width: 100%;
          height: 40px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          transition: 0.3s;
          a{
            text-decoration:none ;
            font-size: 16px;
            color: #495990;
            transition: 0.5s;
          }
          a:hover{
            transition: 0.5s;
            transform: scale(1.01);
            color: #798dcc;
          }
          #regLink{
            font-weight: 500;
          }
        }
        input[type="text"], input[type="email"], select {
          width: 90%;
          height: 45px;
          padding-left: 20px;
          margin: 8px 0;
          border: 0.8px solid #AAA9A9;
          font-size: 16px;
          font-family: "Montserrat Alternates", sans-serif;
          background-color: #f2f0f8;
          transition: 0.3s;
        }

        h3 {
          font-size: 22px;
          color: #495990; /* Тонка лінія під заголовком */
          margin-bottom: 10px;
          transition: 0.3s;
        }

        .radioGroupContainer {
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-direction: column;
          width: 90%;
          margin-bottom: 20px;
          transition: 0.3s;

        }

        .deliverySelect {
          width: 90%;
          height: 40px;
          margin: 8px 0;
          padding: 0;
          background-color: #f2f0f8;
          font-size: 16px;
          font-weight: 200;
          text-align: center;
          border: none;
          transition: 0.3s;
        }
        .noPriceP{
          width: 90%;
          box-sizing: border-box;
          font-size: 14px;
          text-align: center;
        }
      }

      #orderFormLeftInnerContainer {
        width: 400px;
      }

      #orderFormRightInnerContainer {
        width: 310px;
      }


    }

    h3 {
      color: #de3333; /* Червоний колір тексту */
      margin-top: 16px;
      margin-bottom: 8px;
    }

    button:disabled {
      background-color: #e6e6e6; /* Світло-сірий колір для відключеної кнопки */
      cursor: not-allowed;
    }

    h2 {
      text-align: center;
      margin-top: 16px;
    }
  }
}
@media (min-width: 1000px) {
  .makeOrderForm {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 1000px; /* Зменшуємо ширину порівняно з корзиною для кращої адаптації під форму */
    margin: 45px auto; /* Центрування форми */
    padding: 20px 0;
    background-color: #f2f0f8; /* Білий фон */
    .orderFormHeader{
      display: flex;
      justify-content: center;
      font-size: 24px;
      font-weight: 500;
      width: 100%;
      height: 45px;
      border-bottom: 1px solid grey;
    }
    form {
      display: grid;
      grid-template-columns: repeat(2, 0fr);
      align-items: start;
      justify-content: space-between;
      width: 100%;
      flex-direction: column;

      .makeOrderInnerFormContainer {
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        border: 1px solid black;
        margin: 18px 0;
        .orderFormRegister{
          width: 100%;
          height: 50px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          a{
            text-decoration:none ;
            font-size: 18px;
            color: #495990;
            transition: 0.5s;
          }
          a:hover{
            transition: 0.5s;
            transform: scale(1.01);
            color: #798dcc;
          }
          #regLink{
            font-weight: 500;
          }
        }
        input[type="text"], input[type="email"], select {
          width: 90%;
          height: 45px;
          padding-left: 25px;
          margin: 10px 0;
          border: 0.8px solid #AAA9A9;
          font-size: 18px;
          font-family: "Montserrat Alternates", sans-serif;
          background-color: #f2f0f8;
        }

        h3 {
          font-size: 25px;
          color: #495990; /* Тонка лінія під заголовком */
          margin-bottom: 12px; /* Відступ між заголовком та першою радіогрупою */
        }

        .radioGroupContainer {
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-direction: column;
          width: 90%;
          margin-bottom: 25px;


        }

        .deliverySelect {
          width: 90%;
          height: 45px;
          margin: 10px 0;
          padding: 0;
          background-color: #f2f0f8;
          font-size: 18px;
          font-weight: 200;
          text-align: center;
          border: none;
        }
      }

      #orderFormLeftInnerContainer {
        width: 500px;
      }

      #orderFormRightInnerContainer {
        width: 440px;
      }


    }

    h3 {
      color: #de3333; /* Червоний колір тексту */
      margin-top: 18px;
      margin-bottom: 9px;
    }

    button {
      padding: 9px 18px;
      background-color: #de3333; /* Червоний фон кнопки */
      color: #ffffff; /* Білий текст */
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }

    button:hover {
      background-color: #c20808; /* Темно-червоний при наведенні */
    }

    button:disabled {
      background-color: #e6e6e6; /* Світло-сірий колір для відключеної кнопки */
      cursor: not-allowed;
    }

    h2 {
      text-align: center;
      margin-top: 18px;
    }
  }
}
@media (min-width: 1280px) {
  .makeOrderForm {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 1280px; /* Зменшуємо ширину порівняно з корзиною для кращої адаптації під форму */
    margin: 50px auto; /* Центрування форми */
    padding: 20px 0;
    background-color: #f2f0f8; /* Білий фон */
    .orderFormHeader{
      display: flex;
      justify-content: center;
      font-size: 28px;
      font-weight: 500;
      width: 100%;
      height: 50px;
      border-bottom: 1px solid grey;
    }
    form {
      display: grid;
      grid-template-columns: repeat(2, 0fr);
      align-items: start;
      justify-content: space-between;
      width: 100%;
      flex-direction: column;

      .makeOrderInnerFormContainer {
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        border: 1px solid black;
        margin: 20px 0;
        .orderFormRegister{
          width: 100%;
          height: 60px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          a{
            text-decoration:none ;
            font-size: 24px;
            color: #495990;
            transition: 0.5s;
          }
          a:hover{
            transition: 0.5s;
            transform: scale(1.01);
            color: #798dcc;
          }
          #regLink{
            font-weight: 500;
          }
        }
        input[type="text"], input[type="email"], select {
          width: 90%;
          height: 50px;
          padding-left: 30px;
          margin: 12px 0;
          border: 1px solid #AAA9A9;
          font-size: 20px;
          font-family: "Montserrat Alternates", sans-serif;
          background-color: #f2f0f8;
        }

        h3 {
          font-size: 28px;
          color: #495990; /* Тонка лінія під заголовком */
          margin-bottom: 15px; /* Відступ між заголовком та першою радіогрупою */
        }

        .radioGroupContainer {
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-direction: column;
          width: 90%;
          margin-bottom: 30px;


        }

        .deliverySelect {
          width: 90%;
          height: 50px;
          margin: 12px 0;
          padding: 0;
          background-color: #f2f0f8;
          font-size: 20px;
          font-weight: 200;
          text-align: center;
        }
      }

      #orderFormLeftInnerContainer {
        width: 710px;
      }

      #orderFormRightInnerContainer {
        width: 494px;
      }


    }

    h3 {
      color: #de3333; /* Червоний колір тексту */
      margin-top: 20px;
      margin-bottom: 10px;
    }

    button {
      padding: 10px 20px;
      background-color: #de3333; /* Червоний фон кнопки */
      color: #ffffff; /* Білий текст */
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }

    button:hover {
      background-color: #c20808; /* Темно-червоний при наведенні */
    }

    button:disabled {
      background-color: #e6e6e6; /* Світло-сірий колір для відключеної кнопки */
      cursor: not-allowed;
    }

    h2 {
      text-align: center;
      margin-top: 20px;
    }
  }
}