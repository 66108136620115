 @media(min-width: 10px){
  .advantages {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    background-color: #F2F0F8;

    .mainSign {
      width: 100%;
      display: flex;
      justify-content: center;
      color: #3E3E40;
      transition: 0.3s;
      font-weight: bold;
      font-size: 24px;
    }

    .advantagesContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      transition: 0.3s;
      width: 320px;

      .advantage {
        display: flex;
        align-items: center;

        width: 320px;
        transition: 0.3s;
        margin-bottom: 15px;
        img {
          transition: 0.3s;
          width: 162px;
        }

        .advantageSign {
          transition: 0.3s;
          margin-left: 10px;
          h2 {
            color: #495990;
            transition: 0.3s;
            text-align: start;
            font-size: 14px;

          }

          p {
            transition: 0.3s;
            text-align: start;
            font-weight: 400;
            font-size: 10px;

          }
        }
      }
      #even{
        flex-direction: row;
      }
      #odd{
        flex-direction: row-reverse;
      }

    }
  }
}
@media (min-width: 770px) {
  .advantages {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    background-color: #F2F0F8;

    .mainSign {
      width: 100%;
      display: flex;
      justify-content: center;
      color: #3E3E40;
      transition: 0.3s;
      font-weight: bold;
      font-size: 24px;
    }

    .advantagesContainer {
      display: grid;
      grid-template-columns: repeat(2, 0fr);
      justify-content: space-between;
      transition: 0.3s;
      width: 770px;

      .advantage {
        display: flex;
        align-items: center;
        padding-bottom: 54px;
        width: 360px;
        height: 385px;
        transition: 0.3s;

        img {
          transition: 0.3s;
          width: 100%;
          &:hover{
            transform: scale(1.1);
          }
        }

        .advantageSign {
          transition: 0.3s;

          h2 {
            color: #495990;
            transition: 0.3s;
            text-align: center;
            font-size: 20px;
          }

          p {
            transition: 0.3s;
            text-align: center;
            font-weight: 300;
            font-size: 14px;
          }
        }
      }
      #even, #odd{
        flex-direction: column;
      }

    }
  }
}
@media (min-width: 1000px) {
  .advantages {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    background-color: #F2F0F8;

    .mainSign {
      width: 100%;
      display: flex;
      justify-content: center;
      color: #3E3E40;
      transition: 0.3s;
      font-weight: bold;
      font-size: 30px;
    }

    .advantagesContainer {
      display: grid;
      grid-template-columns: repeat(2, 0fr);
      justify-content: space-between;
      transition: 0.3s;
      width: 1000px;

      .advantage {
        display: flex;
        align-items: center;
        padding-bottom: 54px;
        width: 450px;
        height: 480px;
        transition: 0.3s;

        img {
          transition: 0.3s;
          width: 100%;
        }

        .advantageSign {
          transition: 0.3s;

          h2 {
            color: #495990;
            transition: 0.3s;
            text-align: center;
            font-size: 24px;
          }

          p {
            transition: 0.3s;
            text-align: center;
            font-weight: 300;
            font-size: 17px;
          }
        }
      }
      #even, #odd{
        flex-direction: column;
      }

    }
  }
}
@media (min-width: 1280px) {
  .advantages {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    background-color: #F2F0F8;

    .mainSign {
      width: 100%;
      display: flex;
      justify-content: center;
      color: #3E3E40;
      transition: 0.3s;
      font-weight: bold;
      font-size: 36px;
    }

    .advantagesContainer {
      display: grid;
      grid-template-columns: repeat(2, 0fr);
      justify-content: space-between;
      transition: 0.3s;
      width: 1280px;

      .advantage {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 54px;
        width: 540px;
        height: 578px;
        transition: 0.3s;

        img {
          transition: 0.3s;
          width: 100%;
        }

        .advantageSign {
          transition: 0.3s;

          h2 {
            color: #495990;
            transition: 0.3s;
            text-align: center;
            font-size: 28px;
          }

          p {
            transition: 0.3s;
            text-align: center;
            font-weight: 300;
            font-size: 20px;
          }
        }
      }
      #even, #odd{
        flex-direction: column;
      }

    }
  }
}