
body {
  display: flex;
  justify-content: center;
  margin: 0;
}

.headerAdmin {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #b04941;
  width: 100vw;
  position: fixed; /* Додаємо фіксовану позицію */
  top: 0; /* Встановлюємо хедер у верхній частині сторінки */
  left: 0; /* Розміщуємо хедер на початку по горизонталі */
  z-index: 1000;

  .headerAdminContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    position: relative;
    transition: 0.3s;
    box-sizing: border-box;
    .linksContainer{
      a{
        text-decoration: none;
        color: #f2f0f8;
        border-bottom: 1px solid #f2f0f8;
        transition: 0.3s;

      }
      a:hover{
        transition: 0.3s;
        background-color: #d57570;
      }
    }
    @media (min-width: 10px) {
      height: 80px;
      width: 320px;
      h2{
        font-size: 14px;
      }
      .linksContainer{
        display: grid;
        grid-template-columns: repeat(2, 0fr);
        a{
          margin-right: 0px;
          font-size: 12px;
          padding: 7px 10px;
          text-align: center;

        }
      }
    }
    @media (min-width: 770px) {
      height: 80px;
      width: 770px;
      h2{
        font-size: 20px;
      }

      .linksContainer{
        display: flex;
        justify-content: space-around;
        a{
          margin-right: 20px;
          font-size: 14px;
          padding: 7px 10px;
        }
      }
    }
    @media (min-width: 1000px) {
      height: 80px;
      width: 1000px;
      .linksContainer{
        a{
          margin-right: 20px;
          font-size: 20px;
          padding: 7px 10px;
        }
      }
    }
    @media (min-width: 1280px) {
      height: 90px;
      width: 1280px;
      .linksContainer{
        a{
          margin-right: 20px;
          font-size: 20px;
          padding: 7px 10px;
        }
      }
    }
  }

}
