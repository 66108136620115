.itemsInOrderConatiner {

  align-items: start;
  justify-content: space-between;
  .itemInOrder {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: end;
    padding: 0 10px;
    margin: 25px 15px;
    width: 280px;
    height: 270px;
    border: 0.5px solid black;
    .itemInfoTop{
      h3, h4, p{
        margin: 3px 0;
      }
      h4{
        font-size: 18px;
      }
      h3{
        color: #b04941;
        font-size: 16px;
        transition: 0.3s;
      }
      h3:hover{
        color: #495990;
        transition: 0.3s;
        transform: scale(1.02);
      }
      p{
        display: flex;
        justify-content: space-between;
      }

      a{
        text-decoration: none;
      }

    }
    .itemInfoBottom{
      display: flex;
      align-items: center;
      .itemInfoInnerBottom{
        width: 150px;
      }
      img {
        height: 120px;
        width: 120px;
        box-sizing: border-box;
        transition: 0.3s;
        cursor: pointer;
      }
      img:hover{
        transform: scale(1.9);
        transition: 0.3s;
      }
    }
  }
  @media (min-width: 10px) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 770px) {
    display: grid;
    grid-template-columns: repeat(3, 0fr);
  }
}