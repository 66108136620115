.changeConstants{
  width: 100vw;
  display: flex;
  justify-content: center;
  transition: 0.3s;
  .changeConstantsContainer{
    min-height: 80vh;
    transition: 0.3s;
    .changeConstantsHorizontalContainer{
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0;
      transition: 0.3s;
    }
    @media (min-width: 770px) {
      width: 770px;
      .changeConstantsHorizontalContainer{
        width: 770px;
      }
    }
    @media (min-width: 1000px) {
      width: 1000px;
      .changeConstantsHorizontalContainer{
        width: 1000px;
      }
    }
    @media (min-width: 1280px) {
      width: 1280px;
      .changeConstantsHorizontalContainer{
        width: 1280px;
      }
    }
  }
}