 @media(min-width: 10px){
  .inputDiv {
    background-color: #edebf1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    transition: 0.3s;
    color: #4A4949;
    height: 26px;
    width: 275px;

    input {
      width: 70%;
      height: 100%;
      padding: 0 4%;
      border: none;
      background-color: #edebf1;
      transition: 0.3s;
      font-family: "Montserrat Alternates", sans-serif;
      font-weight: 500;
      font-size: 10px;
      &:focus {
        outline: none;
      }
    }

    .inputButton {
      background: none;
      border: none;
      width: 20%;
      height: 100%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s;

      img {
        height: 90%;
        transition: 0.3s;

        &:hover {
          transition: 0.3s;
          transform: scale(1.05);
        }
      }
    }
  }
}
@media (min-width: 770px) {
  .inputDiv {
    background-color: #edebf1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    transition: 0.3s;
    color: #4A4949;
    height: 30px;
    width: 220px;

    input {
      width: 70%;
      height: 100%;
      padding: 0 4%;
      border: none;
      background-color: #edebf1;
      transition: 0.3s;
      font-family: "Montserrat Alternates", sans-serif;
      font-weight: 500;
      font-size: 11px;
      &:focus {
        outline: none;
      }
    }

    .inputButton {
      background: none;
      border: none;
      width: 20%;
      height: 100%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s;

      img {
        height: 90%;
        transition: 0.3s;

        &:hover {
          transition: 0.3s;
          transform: scale(1.05);
        }
      }
    }
  }
}

@media (min-width: 1000px) {
  .inputDiv {
    background-color: #edebf1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    transition: 0.3s;
    color: #4A4949;
    height: 35px;
    width: 334px;

    input {
      width: 70%;
      height: 100%;
      padding: 0 4%;
      border: none;
      background-color: #edebf1;
      transition: 0.3s;
      font-family: "Montserrat Alternates", sans-serif;
      font-weight: 500;
      font-size: 13px;
      &:focus {
        outline: none;
      }
    }

    .inputButton {
      background: none;
      border: none;
      width: 20%;
      height: 100%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s;

      img {
        height: 90%;
        transition: 0.3s;

        &:hover {
          transition: 0.3s;
          transform: scale(1.05);
        }
      }
    }
  }
}

@media (min-width: 1280px) {
  .inputDiv {
    background-color: #edebf1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    transition: 0.3s;
    color: #4A4949;
    height: 50px;
    width: 490px;

    input {
      width: 70%;
      height: 100%;
      padding: 0 4%;
      border: none;
      background-color: #edebf1;
      transition: 0.3s;
      font-family: "Montserrat Alternates", sans-serif;
      font-weight: 500;
      font-size: 18px;
      &:focus {
        outline: none;
      }
    }

    .inputButton {
      background: none;
      border: none;
      width: 20%;
      height: 100%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s;

      img {
        height: 90%;
        transition: 0.3s;

        &:hover {
          transition: 0.3s;
          transform: scale(1.05);
        }
      }
    }

  }
}
