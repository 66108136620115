 @media(min-width: 10px){
  .cartPage{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
    .firstTotalPrice{
      width: 320px;
      border-top: 1px solid #000000;
      padding-top: 10px;
    }
  .cartBottomContainer {
    width: 320px;
    border-top: 1px solid #3E3E40;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    transition: 0.3s;

    button {
      border: none;
      background-color: #495990;
      font-family: "Montserrat Alternates", sans-serif;
      font-size: 18px;
      color: #f2f0f8;
      width: 320px;
      height: 32px;
      cursor: pointer;
      transition: 0.3s;

    }

    button:hover {
      transition: 0.3s;
      background-color: #b72b2b;
    }
  }
}
@media (min-width: 770px) {
  .firstTotalPrice{
    width: 770px;
    margin-left: 20px;
    border-top: 1px solid #000000;
    padding-top: 10px;
  }
  .cartBottomContainer {
    width: 770px;
    border-top: 1px solid #3E3E40;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    margin-bottom: 50px;
    transition: 0.3s;
    margin-left: 20px;
    button {
      border: none;
      background-color: #495990;
      font-family: "Montserrat Alternates", sans-serif;
      font-size: 18px;
      color: #f2f0f8;
      width: 273px;
      height: 32px;
      cursor: pointer;
      transition: 0.3s;

    }

    button:hover {
      transition: 0.3s;
      background-color: #b72b2b;
    }
  }
}
@media (min-width: 1000px) {
  .firstTotalPrice{
    width: 1000px;
  }
  .cartBottomContainer {
    width: 1000px;
    border-top: 1px solid #3E3E40;
    display: flex;
    justify-content: space-between;
    padding-top: 12px;
    margin-bottom: 65px;

    button {
      border: none;
      background-color: #495990;
      font-family: "Montserrat Alternates", sans-serif;
      font-size: 22px;
      color: #f2f0f8;
      width: 340px;
      height: 38px;
      cursor: pointer;
      transition: 0.3s;

    }

    button:hover {
      transition: 0.3s;
      background-color: #b72b2b;
    }
  }
}
@media (min-width: 1280px) {
  .firstTotalPrice{
    width: 1280px;
  }
  .cartBottomContainer {
    width: 1280px;
    border-top: 1px solid #3E3E40;
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    margin-bottom: 80px;


    button {
      border: none;
      background-color: #495990;
      font-family: "Montserrat Alternates", sans-serif;
      font-size: 26px;
      color: #f2f0f8;
      width: 410px;
      height: 45px;
      cursor: pointer;
      transition: 0.3s;

    }

    button:hover {
      transition: 0.3s;
      background-color: #b72b2b;
    }
  }
}