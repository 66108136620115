html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  //overflow-y: hidden;
}
.adminLayout {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  height: auto;
  background-color: #F2F0F8;
  padding-top: 150px;
  width: 100vw;

  .contentContainer {
    // @media(min-width: 10px){
    //  padding-bottom: 840px;
    //}
    //
    //@media (min-width: 770px) {
    //  padding-bottom: 436px;
    //}
    //@media (min-width: 1000px) {
    //  padding-bottom: 540px;
    //}
    //@media (min-width: 1200px) {
    //
    //  padding-bottom: 650px;
    //}
  }
}

