
 @media(min-width: 10px){
  .footer {
    height: 850px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100vw;
    color: white; /* Assuming you want the text in white color for better visibility */
    display: flex;
    align-items: center;
    flex-direction: column;
    .footerPolicyContainer {
      position: absolute;
      bottom: 80px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      color: #333333;
      width: 320px;
      //margin-top: 34px;
      font-size: 8px;
      .madeWithContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        p, h4 {
          margin: 4px 0;
        }

        a {
          color: #282828;
          margin: 7px 0;
        }
      }
      border-top: 1px solid black;
    }
  }
}
@media (min-width: 770px) {
  .footer {
    height: 436px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100vw;
    color: white; /* Assuming you want the text in white color for better visibility */
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 0;
    .footerPolicyContainer {
      position: relative;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      color: #333333;
      width: 770px;
      margin-top: 34px;
      font-size: 12px;
      .footerRightsDiv{
        width: 60%;
      }
      .madeWithContainer {
        display: flex;
        flex-direction: column;
        width: 40%;
        p, h4 {
          margin: 10px 0;
        }

        a {
          color: #282828;
          margin: 7px 0;
        }
      }

      border-top: 3px solid black;
    }
  }
}
@media (min-width: 1000px) {
  .footer {
    height: 545px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100vw;
    color: white; /* Assuming you want the text in white color for better visibility */
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 0;
    .footerPolicyContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #333333;
      width: 1000px;
      margin-top: 42px;
      font-size: 14px;
      .footerRightsDiv{
        width: 60%;
      }
      .madeWithContainer {
        display: flex;
        flex-direction: column;

        p, h4 {
          margin: 10px 0;

        }

        a {
          color: #282828;
          margin: 7px 0;
        }
      }

      border-top: 3px solid black;
    }
  }
}
@media (min-width: 1280px) {
  .footer {
    height: 654px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100vw;
    color: white; /* Assuming you want the text in white color for better visibility */
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 0;
    .footerPolicyContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #333333;
      width: 1280px;
      margin-top: 50px;
      font-size: 18px;
      .footerRightsDiv{
        width: 60%;
      }
      .madeWithContainer {
        display: flex;
        flex-direction: column;

        p, h4 {
          margin: 10px 0;
        }

        a {
          color: #282828;
          margin: 7px 0;
        }
      }

      border-top: 3px solid black;
    }
  }
}