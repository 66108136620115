 @media(min-width: 10px){
  .mobileHeaderContacts{
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 100%;
      height: 100%;
      transition: 0.3s;

    }
    img:hover{
      transition: 0.3s;
      transform: scale(1.1);
    }
    .additionalContacts {
      position: absolute;
      //background-color: #f2f0f8;
      bottom: 60px;
      //height: 120px;
      width: 160px;
      left: 0;
      right: 0;
      z-index: 100;
      padding: 10px;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
      display: flex;
      //align-items: center;
      flex-direction: column;
      justify-content: space-between;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s, visibility 0.3s;
      .additionalPhones{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        a {
          color: #1d3881;
          text-decoration: none;
          cursor: pointer;
          transition: 0.3s;
          font-weight: 500;
          font-size: 20px;
          &:hover {
            color: #3b5cc7;
          }
        }
      }

    }

    &:hover .additionalContacts {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.5s, visibility 0.5s;

    }
  }
}
@media (min-width: 770px) {
  .contacts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    a {
      color: #1d3881;
      text-decoration: none;
      cursor: pointer;
      transition: 0.3s;
      font-weight: 500;
      font-size: 16px;
      &:hover {
        color: #3b5cc7;
      }
    }
    img {
      height: 16px;
      transition: 0.3s;
      margin-left: 8px;

      &:hover {
        transform: scale(1.1);
      }
    }

    .additionalContacts {
      position: absolute;
      //background-color: rgba(242, 240, 248, 0.81);
      top: 42px;
      //height: 100px;
      left: 0;
      right: 0;
      z-index: 100;
      padding: 10px;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s, visibility 0.3s;

    }

    &:hover .additionalContacts {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.5s, visibility 0.5s;

    }

    //@media (min-width: 770px) {
    //  a {
    //    font-size: 16px;
    //  }
    //}
  }
}
@media (min-width: 1000px) {
  .contacts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    a {
      color: #1d3881;
      text-decoration: none;
      cursor: pointer;
      transition: 0.3s;
      font-weight: 500;
      font-size: 20px;
      &:hover {
        color: #3b5cc7;
      }
    }
    img {
      height: 20px;
      transition: 0.3s;
      margin-left: 10px;

      &:hover {
        transform: scale(1.1);
      }
    }

    .additionalContacts {
      position: absolute;
      //background-color: rgba(242, 240, 248, 0.81);
      top: 42px;
      //height: 100px;
      left: 0;
      right: 0;
      z-index: 100;
      padding: 10px;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s, visibility 0.3s;

    }

    &:hover .additionalContacts {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.5s, visibility 0.5s;

    }

    //@media (min-width: 770px) {
    //  a {
    //    font-size: 16px;
    //  }
    //}
  }
}

@media (min-width: 1280px) {
  .contacts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    a {
      color: #1d3881;
      text-decoration: none;
      cursor: pointer;
      transition: 0.3s;
      font-weight: 500;
      font-size: 24px;
      &:hover {
        color: #3b5cc7;
      }
    }
    img {
      height: 24px;
      transition: 0.3s;
      margin-left: 10px;

      &:hover {
        transform: scale(1.1);
      }
    }

    .additionalContacts {
      position: absolute;
      //background-color: rgba(242, 240, 248, 0.81);
      top: 42px;
      //height: 100px;
      left: 0;
      right: 0;
      z-index: 100;
      padding: 10px;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s, visibility 0.3s;

    }

    &:hover .additionalContacts {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.5s, visibility 0.5s;

    }

    //@media (min-width: 770px) {
    //  a {
    //    font-size: 16px;
    //  }
    //}
    //
    //@media (min-width: 1000px) {
    //  a {
    //    font-size: 16px;
    //  }
    //}
    //
    //@media (min-width: 1280px) {
    //  a {
    //    font-size: 24px;
    //  }
    //  img {
    //    height: 24px;
    //    transition: 0.3s;
    //    margin-left: 10px;
    //
    //    &:hover {
    //      transform: scale(1.1);
    //    }
    //  }
    //  .additionalContacts {
    //    top: 42px;
    //    height: 100px;
    //  }
    //}
  }
}
