.activateAccountPage{
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  .activateAccountContainer{
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;

    p{
      color: #3E3E40;
      font-weight: 500;
      margin: 0;
      text-align: center;
    }
    a{
      text-decoration: none;
      color: #f2f0f8;
      background-color: #495990;
      font-weight: 500;
      transition: 0.3s;
      margin-top: 50px;
      &:hover{
        background-color: #646c8d;
        transition: 0.3s;
      }
    }
     @media(min-width: 10px){
      width: 320px;
      margin-top: 30px;
      padding-bottom: 10px;
      p{
        font-size: 24px;
      }
      a{
        padding: 7px 25px;
        font-size: 16px;
      }
      img{
        width: 320px;
        height: 300px;

      }
    }
    @media (min-width: 770px) {
      width: 770px;
      margin-top: 30px;
      padding-bottom: 35px;
      p{
        font-size: 24px;
      }
      a{
        padding: 7px 30px;
        font-size: 24px;
      }
      img{
        width: 450px;
        height: 400px;

      }
    }
    @media (min-width: 1000px) {
      width: 1000px;
      margin-top: 35px;
      padding-bottom: 40px;
      p{
        font-size: 28px;
      }
      a{
        padding: 10px 40px;
        font-size: 28px;
      }
      img{
        width: 500px;
        height: 470px;

      }
    }
    @media (min-width: 1280px) {
      width: 1280px;
      margin-top: 40px;
      padding-bottom: 50px;
      p{
        font-size: 32px;
      }
      a{
        padding: 15px 50px;
        font-size: 32px;
      }
      img{
        width: 555px;
        height: 508px;

      }
    }
  }
}