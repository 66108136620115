 @media(min-width: 10px){
  .footerInfoContainer {
    width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 500px;
    .mobileInnerInfoFooterContainer{
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: auto;
      align-items: center;
      margin-bottom: 20px;
    }
    .footerInfoInnerContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 320px;
      width: 50%;
      .footerLogoImg {
        width: 286px;
      }
      .footerInfoDiv, .footerInfoDivEvac, .footerInfoDivService, .footerInfoDivUsa {
        display: flex;
        flex-direction: column;
        justify-content: left;
        .footerSocialLinksContainer {
          display: grid;
          grid-template-columns: repeat(2, 0fr);
          width: 100%;
          a {
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.3s;
            height: 36px;
            img {
              height: 100%;
              margin-right: 30px;
            }
          }

          a:hover {
            transition: 0.3s;
            transform: scale(1.1);
          }

        }

        .footerMessengerLinks {
          display: flex;
          flex-direction: row;
          max-width: 100%;

          a {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          a:hover {
            transition: 0.3s;
            transform: scale(1.1);
          }

          img {
            width: 30px;
            margin: 4px;
          }
        }

        .footerWorkTimeText {
          width: 90%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        h5 {
          font-weight: bold;
          font-size: 14px;
        }

        p {
          margin: 4px 0;
          font-size: 12px;
        }

        a {
          text-decoration: none;
          transition: 0.3s;
          font-size: 12px;
          margin-bottom: 5px;
        }

        a:hover {
          transition: 0.3s;
        }
      }
      .footerInfoDiv{
        h5{
          color: #495990;
        }
        p {
          color: black;
        }

        a {
          color: black;
        }

        a:hover {
          color: #F2F0F8;
        }
      }
      .footerInfoDivEvac{
        h5{
          color: #f0d267;
        }
        p {
          color: #f2f0f8;
        }

        a {
          color: #f2f0f8;
        }

        a:hover {
          color: #3E3E40;
        }
      }
      .footerInfoDivService{
        h5{
          color: #599fd2;
        }
        p {
          color: #f2f0f8;
        }

        a {
          color: #f2f0f8;
        }

        a:hover {
          color: #3E3E40;
        }
      }
      .footerInfoDivUsa{
        h5{
          color: #f2f0f8;
        }
        p {
          color: #f2f0f8;
        }

        a {
          color: #f2f0f8;
        }

        a:hover {
          color: #3E3E40;
        }
      }

    }
    #footerLeft{
      width: 55%;
    }
    #footerMiddle{
      width: 40%;
    }
    #footerRight{
      width: 320px;

    }
  }
}
@media (min-width: 770px) {
  .footerInfoContainer {
    width: 770px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 500px;
    .footerInfoInnerContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 292px;
      .footerLogoImg {
        width: 292px;
      }
      .footerInfoDiv, .footerInfoDivEvac, .footerInfoDivService, .footerInfoDivUsa {
        display: flex;
        flex-direction: column;
        justify-content: left;
        width: auto;

        .footerSocialLinksContainer {
          display: flex;
          justify-content: space-between;

          a {
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.3s;
            height: 36px;

            img {
              height: 100%;
            }
          }

          a:hover {
            transition: 0.3s;
            transform: scale(1.1);
          }

        }

        .footerMessengerLinks {
          display: flex;
          flex-direction: row;
          max-width: 100%;

          a {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          a:hover {
            transition: 0.3s;
            transform: scale(1.1);
          }

          img {
            width: 30px;
            margin: 4px;
          }
        }

        .footerWorkTimeText {
          width: 70%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        h5 {
          font-weight: bold;
          font-size: 16px;
        }

        p {
          margin: 4px 0;
          font-size: 14px;
        }

        a {
          text-decoration: none;
          transition: 0.3s;
          font-size: 14px;
          margin-bottom: 5px;
        }

        a:hover {
          transition: 0.3s;
        }
      }


    }
    #footerRight{
      width: auto;

    }
  }
}
@media (min-width: 1000px) {
  .footerInfoContainer {
    width: 1000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 366px;
    .footerInfoInnerContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 366px;
      .footerLogoImg {
        width: 365px;
      }
      .footerInfoDiv, .footerInfoDivEvac, .footerInfoDivService, .footerInfoDivUsa {
        display: flex;
        flex-direction: column;
        justify-content: left;
        width: auto;

        .footerSocialLinksContainer {
          display: flex;
          justify-content: space-between;

          a {
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.3s;
            height: 45px;

            img {
              height: 100%;
            }
          }

          a:hover {
            transition: 0.3s;
            transform: scale(1.1);
          }

        }

        .footerMessengerLinks {
          display: flex;
          flex-direction: row;
          max-width: 100%;

          a {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          a:hover {
            transition: 0.3s;
            transform: scale(1.1);
          }

          img {
            width: 36px;
            margin: 5px;
          }
        }

        .footerWorkTimeText {
          width: 60%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        h5 {
          font-weight: bold;
          font-size: 20px;
        }

        p {
          margin: 5px 0;
          font-size: 15px;
        }

        a {
          text-decoration: none;
          transition: 0.3s;
          font-size: 15px;
          margin-bottom: 5px;
        }

        a:hover {
          transition: 0.3s;
        }
      }

    }
  }
}
@media (min-width: 1280px) {
  .footerInfoContainer {
    width: 1280px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 440px;
    .footerInfoInnerContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 440px;
      .footerLogoImg {
        width: 438px;
      }
      .footerInfoDiv, .footerInfoDivEvac, .footerInfoDivService, .footerInfoDivUsa {
        display: flex;
        flex-direction: column;
        justify-content: left;
        width: auto;

        .footerSocialLinksContainer {
          display: flex;
          justify-content: space-between;

          a {
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.3s;
            height: 54px;

            img {
              height: 100%;
            }
          }

          a:hover {
            transition: 0.3s;
            transform: scale(1.1);
          }

        }

        .footerMessengerLinks {
          display: flex;
          flex-direction: row;
          max-width: 100%;

          a {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          a:hover {
            transition: 0.3s;
            transform: scale(1.1);
          }

          img {
            width: 42px;
            margin: 6px;
          }
        }

        .footerWorkTimeText {
          width: 50%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        h5 {
          font-weight: bold;
          font-size: 24px;
        }

        p {
          margin: 6px 0;
          font-size: 16px;
        }

        a {
          text-decoration: none;
          transition: 0.3s;
          font-size: 16px;
          margin-bottom: 6px;
        }

        a:hover {
          transition: 0.3s;
        }
      }

    }
  }
}