.serviceImageContainer{
    transition: 0.3s;
    position: relative;
    background: none;
    img{
      width: 100%;
      box-sizing: border-box;
      object-fit: cover;

      transition: 0.3s;
    }
    .button{
      position: absolute;
      border: none;
      background-color: #599fd2;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s;
      font-family: "Montserrat Alternates", sans-serif;
      font-weight: 800;
      color: #3E3E40;
      &:hover{
        transition: 0.3s;
        background-color: #2681c2;
      }
    }
    @media(min-width: 200px) {
      width: 770px;
      display: flex;
      justify-content: center;
      img{
        height: 330px;
        object-position: center calc(100% + 50px);
      }
      .button{
        position: absolute;
        left: auto;
        bottom: 25px;
        width: 300px;
        height: 35px;
        font-size: 16px;
      }

    }
    @media(min-width: 770px) {
      width: 1000px;

      img{
        height: 392px;
        object-position: center calc(100% + 100px);
      }
      .button{
        left: 120px;
        bottom: 20px;
        width: 252px;
        height: 30px;
        font-size: 13px;
      }

    }
    @media(min-width: 1000px) {
      width: 1280px;
      img{
        height: 500px;
      }
      .button{
        left: 150px;
        bottom: 25px;
        width: 315px;
        height: 38px;
        font-size: 16px;
      }

    }
    @media(min-width: 1280px) {
      width: 1480px;
      img{
        height: 596px;
      }
      .button{
        left: 100px;
        bottom: 30px;
        width: 378px;
        height: 45px;
        font-size: 20px;
      }

    }


}
