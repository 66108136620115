 @media(min-width: 10px){
  .products {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-around;
    align-items: center;
    width: 320px;
    justify-items: center;
  }
}
@media (min-width: 770px) {
  .products {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
    width: 770px;

  }
}
@media (min-width: 1000px) {
  .products {
    margin-top: 36px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
    width: 1000px;

  }
}
@media (min-width: 1280px) {
  .products {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(5, 240px);
    justify-content: space-between;
    width: 1280px;
  }
}