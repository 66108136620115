 @media(min-width: 10px){
  .features {
    display: flex;
    justify-content: center;
    width: 100vw;
    //background-color: #F2F0F8;

    .featuresContainer {
      display: grid;
      grid-template-columns: repeat(2, 0fr);
      transition: 0.3s;
      width: 320px;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      .feature {
        display: flex;
        justify-content: space-around;
        align-items: center;
        transition: 0.3s;
        width: 136px;


        .imageDiv {
          width: 4040px;
          height: 40px;

          img {
            height: 100%;
          }
        }

        .textDiv {
          height: 40%;
          width: 100%;
          transition: 0.3s;

          p {
            text-align: center;
            transition: 0.3s;
            font-size: 10px;
          }
        }
      }
    }
  }
}

@media (min-width: 770px) {
  .features {
    display: flex;
    justify-content: center;
    width: 100vw;
    background-color: #F2F0F8;

    .featuresContainer {
      display: grid;
      grid-template-columns: repeat(3, 0fr);
      transition: 0.3s;
      width: 770px;
      height: 200px;
      align-items: center;
      justify-content: space-between;

      .feature {
        display: flex;
        justify-content: space-around;
        align-items: center;
        transition: 0.3s;
        width: 240px;

        .imageDiv {
          width: 44px;
          height: 44px;

          img {
            height: 100%;
          }
        }

        .textDiv {
          height: 40%;
          width: 100%;
          transition: 0.3s;

          p {
            text-align: center;
            transition: 0.3s;
            font-size: 12px;
          }
        }
      }
    }
  }
}
@media (min-width: 1000px) {
  .features {
    display: flex;
    justify-content: center;
    width: 100vw;
    background-color: #F2F0F8;

    .featuresContainer {
      display: grid;
      grid-template-columns: repeat(3, 0fr);
      transition: 0.3s;
      width: 1000px;
      height: 250px;
      align-items: center;
      justify-content: space-between;

      .feature {
        display: flex;
        justify-content: space-around;
        align-items: center;
        transition: 0.3s;
        width: 300px;

        .imageDiv {
          width: 54px;
          height: 54px;

          img {
            height: 100%;
          }
        }

        .textDiv {
          height: 40%;
          width: 100%;
          transition: 0.3s;

          p {
            text-align: center;
            transition: 0.3s;
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media (min-width: 1280px) {
  .features {
    display: flex;
    justify-content: center;
    width: 100vw;
    background-color: #F2F0F8;

    .featuresContainer {
      display: grid;
      grid-template-columns: repeat(3, 0fr);
      transition: 0.3s;
      width: 1280px;
      height: 300px;
      align-items: center;
      justify-content: space-between;

      .feature {
        display: flex;
        justify-content: space-around;
        align-items: center;
        transition: 0.3s;
        width: 360px;

        .imageDiv {
          width: 64px;
          height: 64px;

          img {
            height: 100%;
          }
        }

        .textDiv {
          height: 40%;
          width: 100%;
          transition: 0.3s;

          p {
            text-align: center;
            transition: 0.3s;
            font-size: 16px;
          }
        }
      }
    }
  }
}