.usaAdvantages{
    transition: 0.3s;
    position: relative;
    background-color: #599080;
    img{
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      object-fit: cover;
      position: absolute;
      transition: 0.3s;
    }
    h3{
      color: #f2f0f8;
      position: absolute;
      margin: 0;
    }

    .usaAdvantagesContainer{
      position: absolute;
      display: flex;
      flex-direction: column;

      p{
        margin-top: 0;
      }
    }
    @media(min-width: 200px) {
      width: 770px;
      height: 450px;
      display: flex;
      justify-content: center;

      img{
        object-position:  calc(100% + 30px);

      }
      .usaAdvantagesContainer{
        top: 70px;
        right: auto;

        p{
          font-size: 16px;
          text-align: left;
          color: #f2f0f8;
          font-weight: 700;
          z-index: 200;
        }
      }
      h3{
        font-size: 20px;
        font-weight: 700;
        top: 8px;
        left: auto;
        z-index: 200;
      }
      &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        background: rgba(0, 0, 0, 0.21);
      }
    }
    @media(min-width: 770px) {
      width: 1000px;
      height: 392px;
      img{

        object-position: center calc(100% + 90px);
      }
      h3{
        font-size: 20px;
        font-weight: 700;
        top: 70px;
        left: 125px;
      }
      .usaAdvantagesContainer{
        top: 70px;
        right: 125px;

        p{
          text-align: right;
          font-size: 16px;
          background: none;
          color: #f2f0f8;
          font-weight: 500;
        }
      }
      .button{
        left: 120px;
        bottom: 20px;
        width: 252px;
        height: 30px;
        font-size: 16px;
      }
      &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        background: rgba(0, 0, 0, 0);
      }
    }
    @media(min-width: 1000px) {
      width: 1280px;
      height: 500px;
      img{

        object-position: center calc(100% + 90px);
      }
      h3{
        font-size: 24px;
        font-weight: 700;
        top: 80px;
        left: 150px;
      }
      .usaAdvantagesContainer{
        top: 80px;
        right: 150px;

        p{
          font-size: 20px;
        }
      }
      .button{
        left: 150px;
        bottom: 40px;
        width: 315px;
        height: 38px;
        font-size: 19px;
      }

    }
    @media(min-width: 1280px) {
      width: 1480px;
      //height: 650px;
      box-sizing: border-box;
      height: 596px;
      img{

      }
      h3{
        font-size: 32px;
        font-weight: 700;
        top: 90px;
        left: 110px;
      }
      .usaAdvantagesContainer{
        top: 90px;
        right: 110px;

        p{
          font-size: 24px;
        }
      }

    }


}
