
.mapContainer{
   @media(min-width: 10px){
    width: 320px;
    height: 178px;
  }
  @media (min-width: 770px) {
    width: 298px;
    height: 178px;
  }
  @media (min-width: 1000px) {
    width: 370px;
    height: 222px;
  }
  @media (min-width: 1280px) {
    width: 446px;
    height: 268px;
  }
  .iFrameMap{
    width: 100%;
    height: 100%;
    border: none;
  }
}
