 @media(min-width: 10px){
  .bigProduct {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 150px;
    height: 250px;
    margin-bottom: 15px; /* Відступ знизу для кожної карточки */
    background:none;
    border: 1px solid #495990;
    overflow: hidden;
    padding: 5px;
    box-sizing: border-box;
    transition: 0.3s;
    //a{
    //  width: 100%;
    //  height: 100%;
    //}
    .product {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 140px;
      height: 184px;
      box-sizing: border-box;
      position: relative;
      .productPriceContainer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 140px;
        position: absolute;
        bottom:-25px;
        p, h4{
          margin: 0;
        }
      }
      img {
        width: 140px; /* Ширина зображення завжди буде розтягуватися на всю ширину контейнера */
        height: 100px; /* Висота буде фіксованою, вказуйте потрібне значення */
        object-fit: cover;
      }
      p, h3, h4, h5 {
        margin: 0;
        text-align: center;

      }
      h3{
        color: #495990;
        font-size: 11px;
        text-align: center;
      }
      h4{
        font-size: 11px;
      }
      p{
        font-size: 10px;

      }
    }

    .buyButton {
      background-color: #495990;
      color: white;
      border: none;
      cursor: pointer;
      transition: 0.3s;
      width: 120px;
      height: 20px;
      font-size: 12px;
      font-weight: 600;
      font-family: "Montserrat Alternates", sans-serif;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .buyButton:hover {
      background-color: #c20808;
      transition: 0.3s;
    }
  }
  .bigProduct:hover{
    box-shadow: 0 2px 8px rgb(112, 112, 112);
    border: 2px solid #495990;
    transform: scale(1.01);
    transition: 0.3s;
  }

}

@media (min-width: 770px) {
  .bigProduct {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 240px;
    height: 400px;
    margin-bottom: 20px; /* Відступ знизу для кожної карточки */
    background:none;
    border: 0.5px solid #808080;
    overflow: hidden;
    padding: 10px;
    box-sizing: border-box;
    transition: 0.3s;
    .product {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 300px;
      width: 220px;
      .productInfo{
        position:absolute;
        top: 170px;
      }
      .productPriceContainer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 220px;
      }
      img {
        width: 220px; /* Ширина зображення завжди буде розтягуватися на всю ширину контейнера */
        height: 156px; /* Висота буде фіксованою, вказуйте потрібне значення */
        object-fit: cover;
      }
      h3, h4, h5 {
        margin: 5px 0;
        text-align: left;
      }
      p{
        margin-top: 10px;
      }
      h3{
        color: #495990;
        font-size: 16px;
        text-align: left;
      }
      h4{
        font-size: 18px;
        text-align: left;

      }
      p{
        font-size: 18px;
        text-align: left;

      }
    }

    .buyButton {
      background-color: #495990;
      color: white;
      border: none;
      cursor: pointer;
      transition: 0.3s;
      width: 150px;
      height: 30px;
      font-size: 16px;
      font-weight: 600;
      font-family: "Montserrat Alternates", sans-serif;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .buyButton:hover {
      background-color: #c20808;
      transition: 0.3s;
    }
  }
  .bigProduct:hover{
    box-shadow: 0 2px 8px rgb(112, 112, 112);
    transform: scale(1.01);
    transition: 0.3s;
  }

}
