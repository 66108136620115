.checkEmailPage{
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 50vh;
  .ActivateText {
    color: #3E3E40;
    text-align: center;
    font-weight: 500;
    width: 70%;

  }
  .linkToLogin {
      text-decoration: none;
      background-color: #495990;
      color: #f2f0f8;
      cursor: pointer;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      transition: 0.4s;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
      transition: 0.4s;
      background-color: #464f6e;
    }
  }
  @media (min-width: 10px) {
    width: 320px;
    .ActivateText {
      font-size: 28px;
    }
    .linkToLogin {
      padding: 15px 50px;
      font-size: 24px;
    }
  }
  @media (min-width: 770px) {
    width: 770px;
    .ActivateText {
      font-size: 28px;
    }
    .linkToLogin {
      padding: 10px 40px;
      font-size: 20px;
    }
  }
  @media (min-width: 1000px) {
    width: 1000px;
    .ActivateText {
      margin-bottom: 20px;
      font-size: 30px;
    }
    .linkToLogin {
      padding: 15px 50px;
      font-size: 26px;
    }
  }
  @media (min-width: 1280px) {
    width: 1280px;
    .ActivateText {
      margin-bottom: 20px;
      font-size: 32px;
    }
    .linkToLogin {
      padding: 15px 50px;
      font-size: 26px;
    }
  }
}