.list{
    margin: 50px;
    border: 1px solid black;
    padding: 30px;
}
.item{
    margin-left: 10px;
    margin-bottom: 10px;
    border: 1px solid black;
    padding: 10px;
}
.listButton{
    width: 100%; /* Повна ширина */
    padding: 10px; /* Відступ всередині кнопки */
    background-color: #652828; /* Червоний фон кнопки */
    color: #fff; /* Білий текст кнопки */
    border: none;
    border-radius: 5px; /* Закруглення кутів кнопки */
    cursor: pointer;
    margin-bottom: 20px; /* Відступ знизу кнопки */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Тінь кнопки */
    transition: background-color 0.3s; /* Плавний перехід для фону кнопки */
}

.listButton:hover {
    background-color: #c20808; /* Темніший відтінок червоного при наведенні */
}