

.manufacturers{
    display: flex;
    justify-content: center;
    width: 100vw;
}
.manufacturersContainer {
    width: 100vw;
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.manufacturersContainer div {
    background-color: #fafafa;
    width: 10%;
    height: 70px ;
    border-radius: 5px;
    margin: 10px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Невелика тінь */
    transition: transform 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    cursor: pointer;
}

.manufacturersContainer div a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    transition: color 0.2s ease-in-out;
}
.manufacturersContainer div:hover {
    transform: scale(1.1);
    background-color: #f5f5f5;
    transition: 0.2s;
}
.paginationDiv {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}