 @media(min-width: 10px){
  .selectCar {
    display: flex;
    justify-content: center;
    width: 100vw;

    .selectCarContainer {
      display: flex;
      flex-direction: column;
      transition: 0.3s;
      width: 320px;

      align-items: flex-start;
      justify-content: center;
      padding-bottom: 30px;

      p {
        color: #495990;
        margin-top: 0;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        width: 100%;
        margin-bottom: 16px;
      }

      .selectCarComponent {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
        box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
        min-width: 100%;
        .searchSelect {
          width: 100%;
          transition: 0.3s;
          background-color: #F2F0F8;
          min-height: 60px;
          min-width: 204px;
          font-size: 18px;
          border-bottom: 1px solid black;
          .css-1fbmsnv-control, .css-1avycum-control, {
            background-color: #F2F0F8;
            font-size: 30px;
            font-weight: 400;
            color: #333333;
          }
        }

        a {
          width: 100%;
          min-height: 50px;
          text-decoration: none;
          background-color: #495990;
          color: white;
          text-align: center;
          transition: background-color 0.3s ease;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-weight: bold;

        }

        a:hover {
          background-color: #1a3180;
        }
      }
    }
  }
}

@media (min-width: 770px) {
  .selectCar {
    display: flex;
    justify-content: center;
    width: 100vw;

    .selectCarContainer {
      display: flex;
      flex-direction: column;
      transition: 0.3s;
      width: 770px;
      align-items: flex-start;
      justify-content: center;
      padding-bottom: 30px;

      p {
        color: #495990;
        margin-bottom: 9px;
        margin-top: 0;
        font-size: 20px;
        font-weight: bold;
        text-align: left;
      }

      .selectCarComponent {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        padding: 0;
        background: none;
        box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
        min-width: 100%;

        .searchSelect {
          width: 27%;
          transition: 0.3s;
          background-color: #F2F0F8;
          height: 50px;
          min-width: 204px;

          .css-1fbmsnv-control, .css-1avycum-control, {
            background-color: #F2F0F8;
            font-size: 30px;
            font-weight: 400;
            color: #333333;
          }
        }

        a {
          width: 19%;
          height: 100%;
          text-decoration: none;
          background-color: #495990;
          color: white;
          text-align: center;
          transition: background-color 0.3s ease;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          font-weight: bold;

        }

        a:hover {
          background-color: #1a3180;
        }
      }
    }
  }
}

@media (min-width: 1000px) {
  .selectCar {
    display: flex;
    justify-content: center;
    width: 100vw;

    .selectCarContainer {
      display: flex;
      flex-direction: column;
      transition: 0.3s;
      width: 1000px;
      align-items: flex-start;
      justify-content: center;
      padding-bottom: 50px;

      p {
        color: #495990;
        margin-bottom: 9px;
        margin-top: 0;
        font-size: 20px;
        font-weight: bold;
      }

      .selectCarComponent {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        padding: 0;
        background: none;
        box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
        min-width: 100%;

        .searchSelect {
          width: 27%;
          transition: 0.3s;
          background-color: #F2F0F8;
          height: 50px;
          min-width: 204px;

          .css-1fbmsnv-control, .css-1avycum-control, {
            background-color: #F2F0F8;
            font-size: 30px;
            font-weight: 400;
            color: #333333;
          }
        }

        a {
          width: 19%;
          height: 100%;
          text-decoration: none;
          background-color: #495990;
          color: white;
          text-align: center;
          transition: background-color 0.3s ease;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          font-weight: bold;

        }

        a:hover {
          background-color: #1a3180;
        }
      }
    }
  }
}
@media (min-width: 1280px) {
  .selectCar {
    display: flex;
    justify-content: center;
    width: 100vw;

    .selectCarContainer {
      display: flex;
      flex-direction: column;
      transition: 0.3s;
      width: 1280px;
      align-items: flex-start;
      justify-content: center;
      padding-bottom: 60px;

      p {
        color: #495990;
        margin-bottom: 15px;
        margin-top: 0;
        font-size: 30px;
        font-weight: bold;
      }

      .selectCarComponent {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        padding: 0;
        background: none;
        box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
        min-width: 100%;

        .searchSelect {
          width: 27%;
          transition: 0.3s;
          background-color: #F2F0F8;
          height: 60px;
          min-width: 204px;

          .css-1fbmsnv-control, .css-1avycum-control, {
            background-color: #F2F0F8;
            font-weight: 400;
            color: #333333;
          }
        }

        a {
          width: 20%;
          height: 100%;
          text-decoration: none;
          background-color: #495990;
          color: white;
          text-align: center;
          transition: background-color 0.3s ease;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          font-weight: bold;

        }

        a:hover {
          background-color: #1a3180;
        }
      }
    }
  }
}