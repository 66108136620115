.orders{

  .orderContainer{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    .order{
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      //padding-top: 50px;
      border: 1px solid black;
      position: relative;
      .saveBut{
        position: absolute;
        background-color: #495990;
        cursor: pointer;
      }
      .orderRowInfo{
        display: flex;
        justify-content: space-around;
        width: 90%;
        align-items: center;
        img{
          width: auto;
          transition: 0.3s;
        }
        select{
          background: none;
          border: 1px solid #b04941;
          font-family: "Montserrat Alternates", sans-serif;
          cursor: pointer;
          //box-shadow: 0px 4px 8px rgba(40, 40, 40, 0.37); /* Тінь кнопки */

        }
        select:hover{
          background-color: #f5f5f5;
        }
      }
      #orderDeliverInfo{
        width: 90%;
        border: 1px solid #b04941;
      }
      button, .orderHeader{
        background-color: #b04941;
        border: none;
        color: #f2f0f8;
        font-size: 20px;
        font-family: "Montserrat Alternates", sans-serif;
        transition: 0.3s;
        margin-top: 0;


      }
      button:hover{
        transition: 0.3s;
        background-color: #d57570;
      }
      .adminOrderDetails{
        transition: 0.3s;
      }
    }
    @media (min-width: 10px) {
      width: 320px;
      .order{
        width: 320px;
        margin: 10px 0;
        h2{
          margin-left: 10px;
        }
        h3{
          margin-left: 10px;
        }
        .saveBut{
          height: 26px;
          top: 267px;
          right: 5px;
          font-size: 8px;
        }
        .orderRowInfo{
          flex-direction: column;
          margin-left: 15px;
          img{
            height: 30px;
          }
          h4{
            font-size: 14px;
          }
          select{
            height: 26px;
            font-size: 20px;
          }
        }
        #orderDeliverInfo{
          padding: 0;
          margin-bottom: 10px;
          p{
            font-size: 12px;
          }
        }
        button, .orderHeader{
          font-size: 20px;
          height: 20px;
        }
      }
    }
    @media (min-width: 770px) {
      width: 770px;
      .order{
        width: 770px;
        margin: 10px 0;
        h2{
          margin-left: 18px;
        }
        h3{
          margin-left: 18px;
        }
        .saveBut{
          height: 26px;
          top: 38px;
          right: 5px;
          font-size: 10px;
        }
        .orderRowInfo{
          flex-direction: row;
          margin-left: 20px;
          img{
            height: 30px;
          }
          h4{
            font-size: 14px;
          }
          select{
            height: 26px;
            font-size: 20px;
          }
        }
        #orderDeliverInfo{
          padding: 0 20px;
          margin-bottom: 10px;
          p{
            font-size: 14px;
          }
        }
        button, .orderHeader{
          font-size: 20px;
          height: 20px;
        }
      }
    }
    @media (min-width: 1000px) {
      width: 1000px;
      .order{
        width: 1000px;
        margin: 10px 0;
        h2{
          margin-left: 18px;
        }
        h3{
          margin-left: 18px;
        }
        .saveBut{
          height: 26px;
          top: 38px;
          right: 5px;
          font-size: 10px;
        }
        .orderRowInfo{
          margin-left: 20px;
          img{
            height: 30px;
          }
          h4{
            font-size: 16px;
          }
          select{
            height: 26px;
            font-size: 20px;
          }
        }
        #orderDeliverInfo{
          padding: 0 20px;
          margin-bottom: 10px;
          p{
            font-size: 18px;
          }
        }
        button, .orderHeader{
          font-size: 20px;
          height: 20px;
        }
      }
    }
    @media (min-width: 1280px) {
      width: 1280px;
      .order{
        width: 1280px;
        margin: 10px 0;
        h2{
          margin-left: 20px;
        }
        h3{
          margin-left: 20px;
        }
        .saveBut{
          height: 30px;
          top: 35px;
          right: 10px;
          font-size: 12px;
        }
        .orderRowInfo{
          margin-left: 20px;
          img{
            height: 30px;
          }
          h4{
            font-size: 18px;
          }
          select{
            height: 26px;
            font-size: 20px;
          }
        }
        #orderDeliverInfo{
          padding: 0 20px;
          margin-bottom: 10px;
          p{
            font-size: 18px;
          }
        }
        button, .orderHeader{
          font-size: 20px;
          height: 20px;
        }
      }
    }
  }

}
