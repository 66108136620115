 @media(min-width: 10px){
  body {
    display: flex;
    justify-content: center;
    margin: 0;
  }
  .Header {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #F2F0F8;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    .headerMainContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      position: relative;
      transition: 0.3s;
      height: 52px;
      width: 320px;
      max-width: 320px;
    }
  }
  .mobileBottomHeader{
    display: flex;
    align-items: center;
    flex-direction: column;
    //background-color: #F2F0F8;
    width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
    .mobileBottomHeaderContainer{
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      position: relative;
      transition: 0.3s;
      height: 60px;
      width: 320px;
      max-width: 320px;
    }
  }
}
@media (min-width: 770px) {
  body {
    display: flex;
    justify-content: center;
    margin: 0;
  }

  .Header {
    display: flex;
    align-items: center;
    flex-direction: column;
    //background-color: #F2F0F8;
    width: 100vw;
    position: fixed; /* Додаємо фіксовану позицію */
    top: 0; /* Встановлюємо хедер у верхній частині сторінки */
    left: 0; /* Розміщуємо хедер на початку по горизонталі */
    z-index: 1000;

    .headerMainContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      position: relative;
      transition: 0.3s;
      height: 60px;
      width: 770px;
      max-width: 770px;
    }

  }
}
@media (min-width: 1000px) {
  body {
    display: flex;
    justify-content: center;
    margin: 0;
  }

  .Header {
    display: flex;
    align-items: center;
    flex-direction: column;
    //background-color: #F2F0F8;
    width: 100vw;
    position: fixed; /* Додаємо фіксовану позицію */
    top: 0; /* Встановлюємо хедер у верхній частині сторінки */
    left: 0; /* Розміщуємо хедер на початку по горизонталі */
    z-index: 1000;

    .headerMainContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      position: relative;
      transition: 0.3s;
      height: 75px;
      width: 1000px;
      max-width: 1000px;
    }

  }
}
@media (min-width: 1280px) {
  body {
    display: flex;
    justify-content: center;
    margin: 0;
  }

  .Header {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    position: fixed; /* Додаємо фіксовану позицію */
    top: 0; /* Встановлюємо хедер у верхній частині сторінки */
    left: 0; /* Розміщуємо хедер на початку по горизонталі */
    z-index: 1000;

    .headerMainContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      position: relative;
      transition: 0.3s;
      height: 90px;
      width: 1280px;
      max-width: 1280px;
    }

  }
}


