.serviceFeatures {
  display: flex;
  justify-content: center;
  width: 100vw;
  background-color: #3E3E40;

  .serviceFeaturesContainer {
    transition: 0.3s;
    align-items: center;
    justify-content: space-between;
    .serviceFeature {
      display: flex;
      justify-content: space-around;
      align-items: center;
      transition: 0.3s;
      height: 80px;
      .serviceImageDiv {
        img {
          height: 100%;
        }
      }
      .serviceTextDiv {
        height: 40%;
        width: 100%;
        transition: 0.3s;
        display: flex;
        align-items: center;
        justify-content: space-around;

        p {
          text-align: right;
          transition: 0.3s;
          color: #f2f0f8;
          margin: 0;
        }
      }
    }
  }
}
 @media(min-width: 10px){
  .serviceFeatures {


    .serviceFeaturesContainer {
      display: grid;
      grid-template-columns: repeat(2, 0fr);

      width: 320px;


      .serviceFeature {

        width: 136px;


        .serviceImageDiv {
          width: 40px;
          height: 40px;


        }

        .serviceTextDiv {


          p {

            font-size: 10px;
          }
        }
      }
    }
  }
}

@media (min-width: 770px) {
  .serviceFeatures {


    .serviceFeaturesContainer {
      display: grid;
      grid-template-columns: repeat(4, 0fr);

      width: 770px;
      height: 250px;


      .serviceFeature {

        width: 160px;

        .serviceImageDiv {
          width: 44px;
          height: 44px;


        }

        .serviceTextDiv {


          p {

            font-size: 12px;
          }
        }
      }
    }
  }
}
@media (min-width: 1000px) {
  .serviceFeatures {


    .serviceFeaturesContainer {
      display: grid;
      grid-template-columns: repeat(4, 0fr);

      width: 1000px;
      height: 250px;


      .serviceFeature {

        width: 210px;

        .serviceImageDiv {
          width: 54px;
          height: 54px;


        }

        .serviceTextDiv {


          p {

            font-size: 14px;
          }
        }
      }
    }
  }
}
@media (min-width: 1280px) {
  .serviceFeatures {


    .serviceFeaturesContainer {
      display: grid;
      grid-template-columns: repeat(4, 0fr);

      width: 1280px;
      height: 300px;


      .serviceFeature {

        width: 270px;

        .serviceImageDiv {
          width: 64px;
          height: 64px;


        }

        .serviceTextDiv {


          p {

            font-size: 16px;

          }
        }
      }
    }
  }
}