$base-color: #b04941;
$hover-color: #d57570;
$active-color: #272750;
.changeMessengerPhonesForm, .changePhonesForm, .changeEmailForm, .changeSocialLinksForm, .changeEvacPricesForm{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 4px solid #8f3d35;

  .innerChangeMessengerFormDiv, .innerChangeEmailFormDiv, .innerChangeSocialLinksFormDiv, .innerChangeEvacPricesFormDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .phonesInputContainer, .InputContainer {
      p{
        color: #3E3E40;
      }
      align-items: center;
      display: flex;
      flex-direction: column;
      .phoneInput {
        border: none;
        box-shadow: 0 2px 4px rgba(0,0,0,0.2);
        font-size: 20px;
        font-family: "Montserrat Alternates", sans-serif;
      }
      .input-labelHorizontalContainer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        p{
          font-weight: 600;
          color: green;
        }
        p::after{
          content: ' :';
        }
        input{

          font-weight: 400;
        }
      }
      .pricesInputsHorizontalContainer{
        display: flex;
        justify-content: space-around;
        align-items: center;
        input{
          font-weight: 400;
          margin: 0;
        }
        h1{
          margin: 0;
          padding: 0;
        }
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;

      .addButton, .deleteButton {
        color: white;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s;
        font-family: "Montserrat Alternates", sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background-color: $hover-color;
        }

        &:active {
          background-color: $active-color;
        }
      }

      .addButton {
        background-color: green;
      }

      .deleteButton {
        background-color: #b22626;
      }
    }

  }
  button{
    background-color: $base-color;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: "Montserrat Alternates", sans-serif;
    width: 100%;
    &:hover {
      background-color: $hover-color;
    }

    &:active {
      background-color: $active-color;
    }
  }
  @media (min-width: 770px) {
    margin-bottom: 70px;
    .innerChangeMessengerFormDiv, .innerChangeEmailFormDiv, .innerChangeSocialLinksFormDiv, .innerChangeEvacPricesFormDiv {
      .phonesInputContainer, .InputContainer {
        p{
          font-size: 14px;
          width: 200px;
        }
        .phoneInput {
          margin-bottom: 10px;
          padding: 8px;
          width: 200px;
          height: 30px;
          font-size: 18px;
        }
        .input-labelHorizontalContainer{
          width: 300px;
          p{
            font-size: 14px;
          }
          input{
            font-size: 14px;
          }
        }
        .pricesInputsHorizontalContainer{
          width: 300px;
          input{
            font-size: 16px;
            width: 150px;
          }
        }
      }
      .buttons {
        margin-top: 95px;
        .addButton, .deleteButton {
          margin: 0 5px 18px 0;
          width: 30px;
          height: 30px;
          font-size: 30px;
        }
      }
    }
    button{
      padding: 5px 20px;
      font-size: 16px;
    }


  }
  @media (min-width: 1000px) {
    margin-bottom: 80px;
    .innerChangeMessengerFormDiv, .innerChangeEmailFormDiv, .innerChangeSocialLinksFormDiv, .innerChangeEvacPricesFormDiv {
      .phonesInputContainer, .InputContainer {
        p{
          font-size: 14px;
          width: 240px;
        }
        .phoneInput {
          margin-bottom: 10px;
          padding: 8px;
          width: 250px;
          height: 30px;
          font-size: 18px;
        }
        .input-labelHorizontalContainer{
          width: 400px;
          p{
            font-size: 16px;
          }
          input{
            font-size: 16px;
          }
        }
        .pricesInputsHorizontalContainer{
          width: 400px;
          input{
            font-size: 16px;
            width: 150px;
          }
        }
      }
      .buttons {
        margin-top: 95px;
        .addButton, .deleteButton {
          margin: 0 20px 18px 0;
          width: 50px;
          height: 50px;
          font-size: 50px;
        }
      }
    }
    button{
      padding: 5px 20px;
      font-size: 20px;
    }


  }
  @media (min-width: 1280px) {
    margin-bottom: 100px;
    .innerChangeMessengerFormDiv, .innerChangeEmailFormDiv, .innerChangeSocialLinksFormDiv, .innerChangeEvacPricesFormDiv {
      .phonesInputContainer, .InputContainer {
        p{
          font-size: 14px;
          width: 270px;
        }
        .phoneInput {
          margin-bottom: 10px;
          padding: 8px;
          width: 300px;
          height: 30px;
          font-size: 20px;
        }
        .input-labelHorizontalContainer{
          width: 450px;
          p{
            font-size: 18px;
          }
          input{
            font-size: 18px;
          }
        }
        .pricesInputsHorizontalContainer{
          width: 450px;
          input{
            font-size: 18px;
            width: 170px;
          }
        }
      }
      .buttons {
        margin-top: 68px;
        .addButton, .deleteButton {
          margin: 0 20px 18px 0;
          width: 70px;
          height: 70px;
          font-size: 60px;
        }
      }
    }
    button{
      padding: 10px 20px;
      font-size: 24px;
    }


  }
}
.changePhones, .changeEmail, .changeSocialLinks{
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 770px) {
    .changePhonesForm, .changeEmailForm, .changeSocialLinksForm{
      width: 370px;
      //margin-top: 50px;
    }
    .changeEmailForm{
      height: 120px;
    }
    .changeSocialLinksForm, .changePhonesForm,{
      min-height: 280px;
    }
  }
  @media (min-width: 1000px) {
    .changePhonesForm, .changeEmailForm, .changeSocialLinksForm{
      width: 420px;
      //margin-top: 50px;
    }
    .changeEmailForm{
      height: 160px;
    }
    .changeSocialLinksForm, .changePhonesForm,{
      min-height: 340px;
    }
  }
  @media (min-width: 1280px) {
    .changePhonesForm, .changeEmailForm, .changeSocialLinksForm{
      width: 500px;
      //margin-top: 50px;
    }
    .changeEmailForm{
      height: 200px;
    }
    .changeSocialLinksForm, .changePhonesForm,{
      min-height: 400px;
    }
  }

}

