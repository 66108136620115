 @media(min-width: 10px){


    .totalPriceContainer {
      width: 320px;
      transition: 0.3s;

      .innerTotalPriceContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 320px;
        transition: 0.3s;

        h2, h3 {
          font-size: 18px;
          margin: 0;
          transition: 0.3s;
        }

        p {
          font-size: 16px;
          font-weight: 200;
          text-decoration: none;
          transition: 0.3s;
        }

        h2 {
          margin-left: 40px;
          color: #495990;
        }

        h3 {
          color: #6c7dbe;
          font-weight: 400;
        }
      }

      p {
        margin: 0;
        font-size: 10px;
        font-weight: 500;
      }

    }
}
@media (min-width: 770px) {

    .totalPriceContainer {
      width: 300px;
      transition: 0.3s;
      .innerTotalPriceContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 246px;
        transition: 0.3s;
        h2, h3 {
          font-size: 18px;
          margin: 0;
          transition: 0.3s;
        }

        p {
          font-size: 16px;
          font-weight: 200;
          text-decoration: none;
          transition: 0.3s;
        }

        h2 {
          color: #495990;
        }

        h3 {
          color: #6c7dbe;
          font-weight: 400;
        }
      }

      p {
        margin: 0;
        font-size: 10px;
        font-weight: 500;
      }

    }
}
@media (min-width: 1000px) {


    .totalPriceContainer {
      width: 375px;

      .innerTotalPriceContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 308px;

        h2, h3 {
          font-size: 21px;
          margin: 0;
        }

        p {
          font-size: 18px;
          font-weight: 200;
          text-decoration: none;
        }

        h2 {
          color: #495990;
        }

        h3 {
          color: #6c7dbe;
          font-weight: 400;
        }
      }

      p {
        margin: 0;
        font-size: 12px;
        font-weight: 500;
      }

    }
}
@media (min-width: 1280px) {

    .totalPriceContainer {
      width: 450px;

      .innerTotalPriceContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 370px;

        h2, h3 {
          font-size: 24px;
          margin: 0;
        }

        p {
          font-size: 20px;
          font-weight: 200;
          text-decoration: none;
        }

        h2 {
          color: #495990;
        }

        h3 {
          color: #6c7dbe;
          font-weight: 400;
        }
      }

      p {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
      }

    }
}