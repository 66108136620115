.mailForm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .mailInputDiv {
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    transition: 0.3s;
    margin-left: 0px;
    input[type="email"] {
      width: 70%;
      height: 100%;
      padding: 0 5%;
      border-radius: 0;
      border: none;
      background-color: #f2f0f8;
      transition: 0.3s;
      font-family: "Montserrat Alternates", sans-serif;
      font-weight: 400;
      &:focus {
        outline: none;
      }
    }

    button {
      background-color: #495990;
      border: none;
      width: 30%;
      height: 100%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      color: white;
      font-family: "Montserrat Alternates", sans-serif;
    }

    button:hover {
      transition: 0.3s;
      background-color: #1a3180;
    }
  }

  .checkBoxDiv {
    display: flex;
    align-items: center;
    margin: 0;
    input[type="checkbox"] {
      appearance: none; /* Відміна стандартного вигляду чекбоксу */
      background-color: #F2F0F8; /* Відсутність фону */
      border: none; /* Відсутність меж */
      border-radius: 0; /* Задання радіуса меж */
      outline: none; /* Заборона відображення фокусу */
      cursor: pointer; /* Зміна курсора при наведенні */
      position: relative;
    }

    input[type="checkbox"]::before {
      content: ""; /* Вставка контенту перед чекбоксом */
      display: inline-block;
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
    }

    input[type="checkbox"]:checked::before {
      background-color: #495990; /* Відсутність фону */
      transform: scale(0.7);
    }

    input[type="checkbox"]:checked::after {
      content: "";
      display: inline-block;
      width: 60%;
      height: 60%;
      border-radius: 20%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: background-color 0.3s ease;

    }

    label {
      color: #F2F0F8;

      a {
        color: #203783;
      }
    }
  }
  @media(min-width: 200px){
    width: 320px;
    height: 30px;
    box-sizing: border-box;

    .mailInputDiv {
      width: 300px;
      height: 24px;
      input[type="email"] {
        font-size: 10px;
      }
      button {
        font-size: 8px;
      }
    }
    .checkBoxDiv {
      font-size: 12px;
      input[type="checkbox"] {
        min-width: 14px;
        height: 14px;
        margin-right: 8px;
        box-sizing: border-box;
      }
      label{
        font-size: 8px;
      }
    }
  }
  @media(min-width: 770px){
    width: 630px;
    height: 50px;
    box-sizing: border-box;
    .mailInputDiv {
      width: 350px;
      max-height: 32px;
      height: 54px;
      input[type="email"] {
        font-size: 14px;
      }
      button {
        font-size: 13px;
      }
    }
    .checkBoxDiv {
      margin-top: 6px;
      font-size: 15px;
      input[type="checkbox"] {
        width: 14px; /* Ширина чекбоксу */
        height: 14px; /* Висота чекбоксу */
        margin-right: 12px
      }
      label{
        font-size: 12px;
      }
    }
  }
  @media(min-width: 1000px){
    width: 800px;
    height: 40px;
    .mailInputDiv {
      width: 456px;
      max-height: 32px;
      height: 54px;
      input[type="email"] {
        font-size: 15px;
      }
      button {
        font-size: 14px;
      }
    }
    .checkBoxDiv {
      margin-top: 6px;
      font-size: 15px;
      input[type="checkbox"] {
        width: 16px; /* Ширина чекбоксу */
        height: 16px; /* Висота чекбоксу */
        margin-right: 12px
      }
      label{
        font-size: 14px;
      }
    }
  }
  @media(min-width: 1280px){
    width: 900px;
    height: 36px;
    .mailInputDiv {
      width: 500px;
      max-height: 32px;
      height: 54px;
      input[type="email"] {
        font-size: 16px;

      }
      button {
        font-size: 16px;
      }
    }
    .checkBoxDiv {
      margin-top: 6px;
      font-size: 15px;
      input[type="checkbox"] {
        width: 18px; /* Ширина чекбоксу */
        height: 18px; /* Висота чекбоксу */
        margin-right: 12px
      }
      label{
        font-size: 16px;
      }
    }
  }
}
