.evacInfo{
  width: 100vw;
  //background-color: #F0D267;
  display: flex;
  align-items: center;
  justify-content: center;
  .evacInfoContainer{
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    font-weight: 700;
    transition: 0.3s;
    p{
      margin: 0;
      color: #3E3E40;
      transition: 0.3s;
    }
    a{
      text-decoration: none;
      color: #3E3E40;

      transition: 0.3s;
      &:hover{
        color: #f2f0f8;
        transition: 0.3s;
      }

    }
    #usaP{
      color: #f2f0f8;
    }
    #usaA{
      color: #f2f0f8;
      &:hover{
        color: #21d5a3;
      }
    }

  }
   @media(min-width: 10px){
    height: 30px;
    .evacInfoContainer{
      width: 320px;
      font-size: 12px;
    }
  }
  @media (min-width: 770px) {
    height: 34px;
    .evacInfoContainer{
      width: 770px;
      font-size: 18px;
      a{
        margin-right: 130px;
      }
    }
  }
  @media (min-width: 1000px) {
    height: 42px;
    .evacInfoContainer{
      width: 1000px;
      font-size: 22px;
      a{
        margin-right: 160px;
      }
    }
  }
  @media (min-width: 1280px) {
    height: 50px;
    .evacInfoContainer{
      width: 1280px;
      font-size: 24px;
      a{
        margin-right: 190px;
      }
    }
  }
}