@media (min-width: 320px) {
  .cartItemContainer {

    position: relative;
    border-top: 1px solid #3E3E40;
    padding: 8px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: 0.3s;

    img {
      width: 134px;
      height: 100px;
      box-sizing: border-box;
      transition: 0.3s;
      object-fit: cover;
    }

    .cartItemInfoContainer {
      width: 176px;
      height: 100px;
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition: 0.3s;


      .itemHeader {
        cursor: pointer;
        font-size: 10px;
        font-weight: bold;
        color: #495990;
        margin: 0;
        transition: 0.3s;
        width: 130px;

      }

      .itemHeader:hover {
        transform: scale(1.01);
        transition: 0.3s;
        color: #6c88ec;
      }

      .cartItemInnerInfoContainer {
        width: 176px;
        transition: 0.3s;

        .cartItemInnerInfo {
          display: flex;
          justify-content: space-between;
          align-items: center;
          transition: 0.3s;
          h4 {
            color: #495990;
          }

          h4, p {
            font-size: 12px;
            margin: 1px 0;
            transition: 0.3s;

          }
        }
      }

      .cartPriceContainer {
        transition: 0.3s;
        height: 30px;
        width: 135px;
        p {
          font-size: 9px;
          font-weight: bold;
          margin: 1px 0;
          transition: 0.3s;
        }

        .cartPriceInnerContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          transition: 0.3s;
          h4, p {
            font-size: 12px;
            margin: 0;
            transition: 0.3s;
          }

          p {
            font-weight: 400;
          }

          h4 {
            font-weight: 600;
          }
        }
      }
    }

    button {
      position: absolute;
      right: 0;
      top: 9px;
      background: none;
      border: none;
      transition: 0.5s ease;
      cursor: pointer;
      img {
        width: 18px;
        height: 18px;
        box-sizing: border-box;
        transition: 0.3s;
      }
    }
  }
}
@media (min-width: 770px) {
  .cartItemContainer {

    position: relative;
    border-top: 1px solid #3E3E40;
    padding: 16px 0;
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: 0.3s;

    img {
      width: 170px;
      height: 135px;
      box-sizing: border-box;
      transition: 0.3s;
      object-fit: cover;
      &:hover{
        transition: 0.3s;
        transform: scale(1.2);
        object-fit: contain;
      }
    }

    .cartItemInfoContainer {
      height: 135px;
      width: auto;
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition: 0.3s;


      .itemHeader {

        cursor: pointer;
        font-size: 12px;
        font-weight: bold;
        color: #495990;
        margin: 0;
        transition: 0.3s;
        width: auto;

      }

      .itemHeader:hover {
        transform: scale(1.01);
        transition: 0.3s;
        color: #6c88ec;
      }

      .cartItemInnerInfoContainer {
        width: 264px;
        transition: 0.3s;

        .cartItemInnerInfo {
          display: flex;
          justify-content: space-between;
          align-items: center;
          transition: 0.3s;

          h4 {
            color: #495990;
          }

          h4, p {
            font-size: 12px;
            margin: 1px 0;
            transition: 0.3s;

          }
        }
      }

      .cartPriceContainer {
        transition: 0.3s;
        height: 30px;
        p {
          font-size: 8px;
          font-weight: bold;
          margin: 1px 0;
          transition: 0.3s;
        }

        .cartPriceInnerContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 264px;
          transition: 0.3s;
          h4, p {
            font-size: 16px;
            margin: 0;
            transition: 0.3s;
          }

          p {
            font-weight: 400;
          }

          h4 {
            font-weight: 600;
          }
        }
      }
    }

    button {
      position: absolute;
      right: 0;
      top: 9px;
      background: none;
      border: none;
      transition: 0.5s ease;
      cursor: pointer;
      img {
        width: 32px;
        height: 32px;
        box-sizing: border-box;
        transition: 0.3s;
      }
    }
  }
}

@media (min-width: 1000px) {
  .cartItemContainer {

    position: relative;
    border-top: 1px solid #3E3E40;
    padding: 18px 0;
    margin-top: 9px;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: 0.5s ease;

    img {
      width: 195px;
      height: 142px;
      box-sizing: border-box;
    }

    .cartItemInfoContainer {
      height: 142px;
      width: auto;
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .itemHeader {
        cursor: pointer;
        font-size: 15px;
        font-weight: bold;
        color: #495990;
        margin: 0;
        transition: 0.3s;

      }

      .itemHeader:hover {
        transform: scale(1.01);
        transition: 0.3s;
        color: #6c88ec;
      }

      .cartItemInnerInfoContainer {
        width: 332px;

        .cartItemInnerInfo {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h4 {
            color: #495990;
          }

          h4, p {
            font-size: 14px;
            margin: 2px 0;
          }
        }
      }

      .cartPriceContainer {
        height: 36px;

        p {
          font-size: 10px;
          font-weight: bold;
          margin: 2px 0;
        }

        .cartPriceInnerContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 332px;

          h4, p {
            font-size: 18px;
            margin: 0;
          }

          p {
            font-weight: 400;
          }

          h4 {
            font-weight: 600;
          }
        }
      }
    }

    button {
      position: absolute;
      right: 0;
      top: 12px;
      background: none;
      border: none;
      transition: 0.5s ease;
      cursor: pointer;

      img {
        width: 36px;
        height: 36px;
        box-sizing: border-box;

      }
    }
  }
}
@media (min-width: 1280px) {
  .cartItemContainer {

    position: relative;
    border-top: 1px solid #3E3E40;
    padding: 20px 0;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: 0.5s ease;

    img {
      width: 235px;
      height: 172px;
      box-sizing: border-box;
    }

    .cartItemInfoContainer {
      height: 172px;
      width: auto;
      margin-left: 18px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .itemHeader {
        cursor: pointer;
        font-size: 20px;
        font-weight: bold;
        color: #495990;
        margin: 0;
        transition: 0.3s;

      }

      .itemHeader:hover {
        transform: scale(1.01);
        transition: 0.3s;
        color: #6c88ec;
      }

      .cartItemInnerInfoContainer {
        width: 400px;

        .cartItemInnerInfo {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h4 {
            color: #495990;
          }

          h4, p {
            font-size: 16px;
            margin: 2px 0;
          }
        }
      }

      .cartPriceContainer {
        height: 44px;

        p {
          font-size: 12px;
          font-weight: bold;
          margin: 2px 0;
        }

        .cartPriceInnerContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 400px;

          h4, p {
            font-size: 20px;
            margin: 0;
          }

          p {
            font-weight: 400;
          }

          h4 {
            font-weight: 600;
          }
        }
      }
    }

    button {
      position: absolute;
      right: 0;
      top: 15px;
      background: none;
      border: none;
      transition: 0.5s ease;
      cursor: pointer;

      img {
        width: 40px;
        height: 40px;
        box-sizing: border-box;

      }
    }
  }
}