// @media(min-width: 10px){
//  .downHeader {
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    //background-color: rgba(73, 89, 144, 0.8);
//    width: 100vw;
//
//    .headerDownContainer {
//      width: 320px;
//      height: 12px;
//      display: flex;
//      justify-content: center;
//      align-items: center;
//      color: #fff;
//      position: relative;
//      transition: 0.3s;
//      box-sizing: border-box;
//
//      div {
//        height: 100%;
//        width: 100%;
//        display: flex;
//        align-items: center;
//        justify-content: space-between;
//
//        a {
//          font-size: 10px;
//          text-decoration: none;
//          transition: 0.3s;
//          font-weight: 700;
//          line-height: 19px;
//          color: #f2f0f8;
//        }
//        a:hover{
//          color: black;
//        }
//      }
//    }
//  }
//}
//
//@media (min-width: 770px) {
//  .downHeader {
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    //background-color: rgba(73, 89, 144, 0.8);
//    width: 100vw;
//
//    .headerDownContainer {
//
//      height: 20px;
//      display: flex;
//      justify-content: space-between;
//      align-items: center;
//      color: #fff;
//      position: relative;
//      transition: 0.3s;
//      margin: 0 12px;
//      width: 770px;
//      box-sizing: border-box;
//
//      div {
//        height: 100%;
//        display: flex;
//        align-items: center;
//
//        a {
//          font-size: 12px;
//          text-decoration: none;
//          transition: 0.3s;
//          font-weight: 700;
//          line-height: 19px;
//        }
//      }
//
//      #downHeaderLeftContainer {
//        a {
//          margin-right: 16px;
//          color: #F2F0F8;
//        }
//
//        a:hover {
//          transition: 0.3s;
//          color: #1E1E1E;
//        }
//      }
//
//      #downHeaderRightContainer {
//
//        a {
//          margin-left: 16px;
//          color: #F2F0F8;
//
//        }
//
//        a:hover {
//          transition: 0.3s;
//          color: #1E1E1E;
//        }
//      }
//    }
//  }
//}
//
//@media (min-width: 1000px) {
//  .downHeader {
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    //background-color: rgba(73, 89, 144, 0.8);
//    width: 100vw;
//
//    .headerDownContainer {
//
//      height: 22px;
//      //width: 100%;
//      display: flex;
//      justify-content: space-between;
//      align-items: center;
//      color: #fff;
//      position: relative;
//      transition: 0.3s;
//      margin: 0 10px;
//      width: 1000px;
//      box-sizing: border-box;
//
//      div {
//        height: 100%;
//        display: flex;
//        align-items: center;
//
//        a {
//          font-size: 14px;
//          text-decoration: none;
//          transition: 0.3s;
//          font-weight: 700;
//          line-height: 15px;
//        }
//      }
//
//      #downHeaderLeftContainer {
//        width: 440px;
//        a {
//          margin-right: 14px;
//        }
//      }
//
//      #downHeaderRightContainer {
//        width: 375px;
//        a {
//          margin-left: 14px;
//        }
//      }
//    }
//  }
//}


  .downHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;

    .headerDownContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      transition: 0.3s;
      box-sizing: border-box;
      .downHeaderInnerContainer{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
          text-decoration: none;
          transition: 0.3s;
          font-weight: 700;
          color: #f2f0f8;
        }
        a:hover {
          transition: 0.3s;
          color: #1E1E1E;
        }
      }
      #downHeaderInnerEvac{
          a {
            color: #1E1E1E;
          }
          a:hover {
            color: #f2f0f8;
          }
      }
      @media (min-width: 10px) {
        height: 25px;
        width: 320px;
        .downHeaderInnerContainer{
          width: 320px;
          a {
            font-size: 9px;
            line-height: 19px;
          }
        }
      }
      @media (min-width: 770px) {
        height: 30px;
        width: 770px;
        .downHeaderInnerContainer{
          width:auto;
          a {
            font-size: 12px;
            line-height: 19px;
            margin: 0 8px;
          }
        }

      }
      @media (min-width: 1000px) {
        height: 40px;
        width: 1000px;
        .downHeaderInnerContainer{
          a {
            font-size: 16px;
            line-height: 19px;
            margin: 0 10px;
          }
        }
      }
      @media (min-width: 1280px) {
        height: 50px;
        width: 1280px;
        .downHeaderInnerContainer{
          a {
            font-size: 20px;
            line-height: 19px;
            margin: 0 12px;
          }
        }
      }
    }
  }

