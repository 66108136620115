 @media(min-width: 10px){
  .horizontalRadioGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 34px;
    border: 1px solid #AAA9A9;
    font-size: 12px;
    background-color: #f2f0f8;
    margin-top: 18px;

    .img-label {
      display: flex;
      align-items: center;
      justify-content: space-between;

      label {
        font-size: 15px;
        font-weight: 300;
      }

      img {
        height: 34px;
        width: 34px;
        margin-right: 10px;
      }
    }

    .radioCircle {
      appearance: none;
      background-color: #f2f0f8; /* Білий фон */
      border: 3px solid #495990; /* Чорна рамка */
      border-radius: 0;
      width: 34px; /* Ширина квадрата */
      height: 34px; /* Висота квадрата */
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      transition: background 0.2s;
      margin: 0;

    }

    .radioCircle:checked {
      background-color: #f2f0f8;
    }

    .radioCircle:checked::before {
      content: '';
      position: absolute;
      left: 2px;
      top: 2px;
      width: 20px;
      height: 20px;
      background: #495990;
      border-radius: 2px;
    }
  }
}
@media (min-width: 770px) {
  .horizontalRadioGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    border: 1px solid #AAA9A9;
    font-size: 12px;
    background-color: #f2f0f8;
    margin-top: 18px;

    .img-label {
      display: flex;
      align-items: center;
      justify-content: space-between;

      label {
        font-size: 15px;
        font-weight: 300;
      }

      img {
        height: 40px;
        width: 40px;
        margin-right: 10px;
      }
    }

    .radioCircle {
      appearance: none;
      background-color: #f2f0f8; /* Білий фон */
      border: 1.5px solid #495990; /* Чорна рамка */
      width: 20px; /* Ширина квадрата */
      height: 20px; /* Висота квадрата */
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      transition: background 0.2s;
      margin-right: 7px;

    }

    .radioCircle:checked {
      background-color: #f2f0f8;
    }

    .radioCircle:checked::before {
      content: '';
      position: absolute;
      left: 2px;
      top: 2px;
      width: 10px;
      height: 10px;
      background: #495990;
      border-radius: 2px;
    }
  }
}
@media (min-width: 1000px) {
  .horizontalRadioGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 45px;
    border: 1px solid #AAA9A9;
    font-size: 14px;
    background-color: #f2f0f8;
    margin-top: 21px;

    .img-label {
      display: flex;
      align-items: center;
      justify-content: space-between;

      label {
        font-size: 18px;
        font-weight: 300;
      }

      img {
        height: 45px;
        width: 45px;
        margin-right: 12px;
      }
    }

    .radioCircle {
      appearance: none;
      background-color: #f2f0f8; /* Білий фон */
      border: 1.5px solid #495990; /* Чорна рамка */
      width: 25px; /* Ширина квадрата */
      height: 25px; /* Висота квадрата */
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      transition: background 0.2s;
      margin-right: 10px;
    }

    .radioCircle:checked {
      background-color: #f2f0f8;
    }

    .radioCircle:checked::before {
      content: '';
      position: absolute;
      left: 3px;
      top: 3px;
      width: 12px;
      height: 12px;
      background: #495990;
      border-radius: 2px;
    }
  }
}
@media (min-width: 1280px) {
  .horizontalRadioGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    border: 1px solid #AAA9A9;
    font-size: 16px;
    background-color: #f2f0f8;
    margin-top: 30px;

    .img-label {
      display: flex;
      align-items: center;
      justify-content: space-between;

      label {
        font-size: 22px;
        font-weight: 300;
      }

      img {
        height: 50px;
        width: 50px;
        margin-right: 15px;
      }
    }

    .radioCircle {
      appearance: none;
      background-color: #f2f0f8; /* Білий фон */
      border: 2px solid #495990; /* Чорна рамка */
      width: 30px; /* Ширина квадрата */
      height: 30px; /* Висота квадрата */
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      transition: background 0.2s;
    }

    .radioCircle:checked {
      background-color: #f2f0f8;
    }

    .radioCircle:checked::before {
      content: '';
      position: absolute;
      left: 3px;
      top: 3px;
      width: 15px;
      height: 15px;
      background: #495990;
      border-radius: 2px;
    }
  }
}