.changeConstantsHeader{
  background-color: #26cb12;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  img{
    margin-left: 50px;
    transition: 0.3s;
  }
  p{
    font-family: "Montserrat Alternates", sans-serif;
  }
  @media (min-width: 770px) {
    height: 40px;
    width: 770px;
    margin-bottom: 30px;
    img{
      height: 20px;
      width: 20px;
      margin-left: 25px;
    }
    p{
      font-size: 14px;
    }
  }
  @media (min-width: 1000px) {
    height: 50px;
    width: 1000px;
    margin-bottom: 30px;
    img{
      height: 25px;
      width: 25px;
      margin-left: 35px;
    }
    p{
      font-size: 16px;
    }
  }
  @media (min-width: 1280px) {
    height: 60px;
    width: 1280px;
    margin-bottom: 30px;
    img{
      height: 30px;
      width: 30px;
      margin-left: 50px;
    }
    p{
      font-size: 16px;
    }
  }
}