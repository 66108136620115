.mainPageInfo{
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  .mainPageInfoContainer{
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    font-weight: 600;
    transition: 0.3s;
    p{
      margin: 0;
      color: #f2f0f8;
      transition: 0.3s;
    }
    a{
      text-decoration: none;
      color: #f2f0f8;
      text-align: right;
      transition: 0.3s;
      &:hover{
        color: #1E1E1E;
        transition: 0.3s;
      }

    }

  }
  @media(min-width: 10px){
    height: 30px;
    .mainPageInfoContainer{
      width: 320px;
      font-size: 14px;
    }
  }
  @media (min-width: 770px) {
    height: 34px;
    .mainPageInfoContainer{
      width: 770px;
      font-size: 20px;
      a{
        margin-right: 60px;
      }
    }
  }
  @media (min-width: 1000px) {
    height: 42px;
    .mainPageInfoContainer{
      width: 1000px;
      font-size: 22px;
      a{
        margin-right: 80px;
      }
    }
  }
  @media (min-width: 1280px) {
    height: 50px;
    .mainPageInfoContainer{
      width: 1280px;
      font-size: 24px;
      a{
        margin-right: 100px;
      }
    }
  }
}