.passwordRecoveryPage{
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p{
    font-weight: 700;
    color: #495990;
    text-align: center;

  }
  form{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border: 0.5px solid #3E3E40;
    p{
      font-weight: 500;
      margin: 0;
    }
    input{
      width: 80%;
      background-color: #f2f0f8;
      border: 0.5px solid #3E3E40;
      font-family: "Montserrat Alternates", sans-serif;
    }
    button{
      border: none;
      background-color: #495990;
      font-weight: 500;
      color: #f2f0f8;
      font-family: "Montserrat Alternates", sans-serif;
      cursor: pointer;
      &:hover{
        background-color: #484f72;
      }
    }
  }
  @media (min-width: 10px) {
    p{
      font-size: 22px;
    }
    form {
      width: 300px;
      height: 200px;

      input {
        height: 30px;
        padding-left: 18px;
      }

      button {
        font-size: 18px;
        padding: 5px 40px;
      }
    }
  }
  @media (min-width: 770px) {
    p{
      font-size: 23px;
    }
    form {
      width: 340px;
      height: 190px;

      input {
        height: 30px;
        padding-left: 18px;
      }

      button {
        font-size: 20px;
        padding: 5px 25px;
      }
    }
  }
  @media (min-width: 1000px) {
    p{
      font-size: 28px;
    }
    form {
      width: 420px;
      height: 220px;

      input {
        height: 30px;
        padding-left: 18px;
      }

      button {
        font-size: 23px;
        padding: 5px 25px;
      }
    }
  }
  @media (min-width: 1280px) {
    p{
      font-size: 32px;
    }
    form {
      width: 500px;
      height: 250px;

      input {
        height: 30px;
        padding-left: 20px;
      }

      button {
        font-size: 26px;
        padding: 5px 30px;
      }
    }
  }
}